import React from 'react';

const Headline = ({title}) => {
  return (
    <div className='w-full border-b border-dashed border-b-black py-2'>
      <span className='text-[16px] font-WorkSans font-[600]'>
        {title}
      </span>
    </div>
  )
}

export default Headline;