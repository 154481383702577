import React from 'react'
import General from './General'
import ProfilePassword from './ProfilePassword'

const MyAccount = () => {
  return (
    <div className='grid grid-cols-2 -sm:grid-cols-1 gap-x-4 w-full -sm:gap-y-16 font-WorkSans'>
      <General />
      <ProfilePassword />
    </div>
  )
}

export default MyAccount