import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import MainLayout from "../MainLayout";
import HorizontalNavigation from "./components/HorizontalNavigation";
import Banner from "./Banner";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../actions/profile";
import ProfileAbout from "./components/ProfileAbout";
import ProfileImage from "./components/ProfileImage";
import ProfileVideo from "./components/ProfileVideo";
import ProfileLink from "./components/ProfileLink";
import ProfileAdmin from "./components/ProfileAdmin";
import "../styles/DetailModal.css";

const Profile = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const [active, setActive] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const basePath = path.replace(":profileId", profileId);

  useEffect(() => {
    if (location.pathname.includes("/about")) {
      setActive(1);
    } else if (location.pathname.includes("/images")) {
      setActive(2);
    } else if (location.pathname.includes("/videos")) {
      setActive(3);
    } else if (location.pathname.includes("/details")) {
      setActive(4);
    } else if (location.pathname.includes("/admins")) {
      setActive(5);
    } else {
      history.push(`${basePath}/about`);
    }
  }, [location]);

  useEffect(() => {
    if (profileId) {
      setIsLoading(true);
      dispatch(get(profileId))
        .then((res) => {
          setIsLoading(false);
          if (res && res.message) {
            history.push("/dashboard/account");
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [profileId]);

  if (isLoading) {
    return null
  }

  return (
    <MainLayout>
      <div className="w-[100%] h-[100%] overflow-x-hidden">
        <Banner profile={profile} />
        <HorizontalNavigation
          active={active}
          onClick={(id, route) => {
            setActive(id);
            history.push(`${basePath}${route}`);
          }}
          children={
            <Switch>
              <Route exact path={`${basePath}/about`}>
                <ProfileAbout profile={profile} />
              </Route>
              <Route exact path={`${basePath}/images`}>
                <ProfileImage profile={profile} />
              </Route>
              <Route exact path={`${basePath}/videos`}>
                <ProfileVideo profile={profile} />
              </Route>
              <Route exact path={`${basePath}/details`}>
                <ProfileLink profile={profile} />
              </Route>
              <Route exact path={`${basePath}/admins`}>
                <ProfileAdmin profile={profile} />
              </Route>
            </Switch>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Profile;
