import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import Headline from "../../dashboard/components/medallion/Headline";

const StatusModal = ({ handleClose, handleChange, status }) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal font-WorkSans">
      <div className="relative mx-auto rounded-lg bg-white -md:w-[96%] md:w-[500px] my-[50px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">Profile type</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={handleClose}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-start space-y-8 px-8 pt-6 pb-12">
          <div className="flex flex-col justify-start items-start w-full space-y-2">
            <Headline title={"Unlinked"} />
            <p className="text-[15px] text-gray-600">
              Unlinked profiles are FREE and fully functional profiles that only
              the creator of the profile can see. You can convert your unlinked
              profile to a limited profile or a fully active and active profile.
            </p>
            {status !== "Unlinked" && (
              <div
                className="flex flex-row justify-start items-center space-x-2 text-white bg-red-500 px-4 py-2 rounded-xl !mt-6 -md:!mt-2 cursor-pointer -sm:text-[13px]"
                onClick={() => {
                  handleChange("Unlinked");
                }}
              >
                Change to Unverified profile
              </div>
            )}
          </div>
          <div className="flex flex-col justify-start items-start w-full space-y-2">
            <Headline title={"Limited"} />
            <p className="text-[15px] text-gray-600">
              Limited profiles are FREE and accessible to the public and
              connected to a My Lasting Memories, but with limited
              accessibility. Visitors can only view the Memorial page but not
              the Tribute page to contribute their own post and interact with
              others.
            </p>
            {status !== "Limited" && (
              <div
                className="flex flex-row justify-start items-center space-x-2 text-white bg-blue-500 px-4 py-2 rounded-xl !mt-6 -md:!mt-2 cursor-pointer -sm:text-[13px]"
                onClick={() => {
                  handleChange("Limited");
                }}
              >
                Change to Limited profile
              </div>
            )}
          </div>
          <div className="flex flex-col justify-start items-start w-full space-y-2">
            <Headline title={"Active"} />
            <p className="text-[15px] text-gray-600">
              Active profiles are paid profiles that are connected to a My Lasting
              Memories.
            </p>
            {/* {status !== "Active" && (
              <div
                className="flex flex-row justify-start items-center space-x-2 text-white bg-green-500 px-4 py-2 rounded-xl !mt-6 -md:!mt-2 cursor-pointer -sm:text-[13px]"
                onClick={() => {handleChange('Active')}}
              >
                Change to Active profile
              </div>
            )} */}
            <div
              className="flex flex-col justify-start items-start space-y-2 rounded-lg bg-gray-200 text-[14px] p-6"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <p className="w-full font-bold text-center text-[20px] text-green-500">
                How to active this account
              </p>
              <p>
                <span className="font-bold text-green-500">Step 1:</span>
                <br /> Buy a medalion
              </p>
              <p>
                <span className="font-bold text-green-500">Step 2:</span>
                <br /> Scan the medalion and select this account from the list
              </p>
              <p>
                <span className="font-bold text-green-500">Step 3:</span>
                <br /> Share the link with family and friends
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
