import React, { useState } from "react";
import moment from "moment";
import BlankAvatarSVG from "../../assets/project/detail/blank.svg";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../global/ConfirmModal";
import TributeModal from "./TributeModal";
import { getTributes, remove, removeTributeImage } from "../../actions/tribute";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const TributeCard = ({ profileId, tribute, user, images }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [showRemoveTributeModal, setShowRemoveTributeModal] = useState(false);
  const [showRemoveTributeImageModal, setShowRemoveTributeImageModal] =
    useState(false);
  const [showTributeModal, setShowTributeModal] = useState(false);
  const [selectedTributeImageId, setSelectedTributeImageId] = useState(null);
  const [selectedTributeId, setSelectedTributeId] = useState(null);
  const [selectedTribute, setSelectedTribute] = useState(null);

  const handleEdit = (tribute) => {
    setSelectedTribute(tribute);
    setShowTributeModal(true);
  };
  const handleRemove = (tributeId) => {
    setShowRemoveTributeModal(true);
    setSelectedTributeId(tributeId);
  };

  return (
    <>
      <div
        className="w-full flex flex-col justify-start items-start space-y-4 p-8 rounded-lg font-WorkSans mt-4"
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="w-full flex flex-row justify-between items-center border-b pb-4">
          <div className="flex flex-row justify-start items-center space-x-2">
            <img
              src={user.avatar && `${BACKEND_URL}/uploads/users/avatar/${user.avatar}` || BlankAvatarSVG}
              className="w-[70px] -sm:w-[50px] h-[70px] -sm:h-[50px] rounded-full border"
            />
            <div className="flex flex-col justify-start items-start">
              <span className="font-bold text-[20px] -sm:text-[14px]">
                {user?.firstname + " " + user?.lastname}
              </span>
              <span className="text-[12px]">
                {moment(tribute.createdAt).startOf("seconds").fromNow()}
              </span>
            </div>
          </div>
          {currentUser?.id === user.id && (
            <div className="flex flex-row justify-center items-center space-x-4 px-4 py-2 rounded-full bg-white border-[#10405A] border -sm:text-[13px]">
              <BsFillPencilFill
                className="text-[#10405A] cursor-pointer"
                onClick={() => handleEdit(tribute)}
              />
              <BsFillTrash3Fill
                className="text-red-600 cursor-pointer"
                onClick={() => handleRemove(tribute.id)}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start items-start space-y-2">
          <p className="font-[600] text-[20px] -sm:text-[16px]">{tribute.title}</p>
          <p className="text-[16px] -sm:text-[14px]">{tribute.description}</p>
        </div>
        <div className="grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 gap-8">
          {images.length > 0 &&
            images.map((image, index) => {
              return (
                <div className="w-full" key={index}>
                  <img
                    src={
                      image?.imageUrl
                        ? `${BACKEND_URL}/uploads/tributes/image/${image.imageUrl}`
                        : BlankAvatarSVG
                    }
                    className="w-full rounded-lg"
                  />
                  {currentUser?.id === user.id && (
                    <div
                      className="w-full flex flex-row justify-center items-center space-x-2 rounded-lg bg-gray-200 mt-2 py-3 cursor-pointer -sm:text-[13px]"
                      onClick={() => {
                        setShowRemoveTributeImageModal(true);
                        setSelectedTributeImageId(image.id);
                      }}
                    >
                      <span className="text-[16px]">Delete</span>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {selectedTributeImageId && showRemoveTributeImageModal && (
        <ConfirmModal
          title={"Remove tribute image"}
          description={"Do you want to remove this tribute image?"}
          confirmTitle={"Delete"}
          cancelTitle={"Cancel"}
          handleConfirm={() => {
            const payload = {
              tributeImageId: selectedTributeImageId,
            };
            dispatch(removeTributeImage(payload))
              .then(() => {
                setShowRemoveTributeImageModal(false);
                setSelectedTributeImageId(null);
                dispatch(getTributes(profileId));
              })
              .catch(() => {
                setShowRemoveTributeImageModal(false);
              });
          }}
          handleCancel={() => {
            setShowRemoveTributeImageModal(false);
            setSelectedTributeImageId(null);
          }}
        />
      )}
      {selectedTributeId && showRemoveTributeModal && (
        <ConfirmModal
          title={"Remove tribute"}
          description={"Do you want to remove this tribute?"}
          confirmTitle={"Delete"}
          cancelTitle={"Cancel"}
          handleConfirm={() => {
            const payload = {
              tributeId: selectedTributeId,
            };
            dispatch(remove(payload))
              .then(() => {
                setShowRemoveTributeModal(false);
                setSelectedTributeId(null);
                dispatch(getTributes(profileId));
              })
              .catch(() => {
                setShowRemoveTributeModal(false);
              });
          }}
          handleCancel={() => {
            setShowRemoveTributeModal(false);
            setSelectedTributeId(null);
          }}
        />
      )}
      {showTributeModal && selectedTribute && profileId && (
        <TributeModal
          selectedTribute={selectedTribute}
          closeUploadModal={() => setShowTributeModal(false)}
          profileId={profileId}
        />
      )}
    </>
  );
};

export default TributeCard;
