import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditImageModal = ({ onClose, selectedImage, isLoading, onSubmit }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, "Name must be at least 2 characters long."),
    description: Yup.string().min(
      2,
      "Description must be at least 2 characters long."
    ),
  });

  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal">
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[90%] md:w-[700px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">Edit image</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={onClose}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center p-8">
          <Formik
            initialValues={{
              title: selectedImage?.title || "",
              description: selectedImage?.description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values, setSubmitting);
            }}
          >
            {() => (
              <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
                <div>
                  <label
                    htmlFor="title"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Title:
                  </label>
                  <Field
                    type="text"
                    id="title"
                    name="title"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Description:
                  </label>
                  <Field
                    component="textarea"
                    id="description"
                    name="description"
                    rows="3"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div className="flex w-full p-4 items-center justify-center font-WorkSans">
                  <button
                    type="submit"
                    className="w-full max-w-xs bg-[#10405A] text-white rounded-lg py-3 hover-bg-indigo-600 transition-colors text-[14px]"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditImageModal;
