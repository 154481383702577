import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import UploadVideoModal from "./UploadVideoModal";
import { useDispatch, useSelector } from "react-redux";
import { getProfileVideos, removeProfileVideo } from "../../../actions/profile";
import VideoCard from "./VideoCard";
import ConfirmModal from "../../global/ConfirmModal";

const ProfileVideo = ({ profile }) => {
  const dispatch = useDispatch();
  const { profileVideos } = useSelector((state) => state.profile);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    if (profile) {
      dispatch(getProfileVideos(profile.id))
        .then(() => {})
        .catch(() => {});
    }
  }, [profile]);

  const handleRemove = (profileVideo) => {
    setSelectedVideo(profileVideo);
    setShowRemoveModal(true);
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center font-WorkSans space-y-4 mb-12">
        <div className="w-full flex flex-row justify-end items-center">
          <div
            className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px]"
            onClick={() => {
              setShowUploadModal(true);
            }}
          >
            <FaPlus />
            <span>Add Video</span>
          </div>
        </div>
        <div className="grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 gap-8">
          {profileVideos.length > 0 &&
            profileVideos.map((profileVideo, index) => {
              return (
                <VideoCard
                  key={index}
                  url={profileVideo.youtubeUrl}
                  handleRemove={() => handleRemove(profileVideo)}
                />
              );
            })}
        </div>
      </div>
      {showUploadModal && (
        <UploadVideoModal
          closeUploadModal={() => setShowUploadModal(false)}
          profile={profile}
        />
      )}
      {showRemoveModal && (
        <ConfirmModal
          title={"Remove video"}
          description={"Do you want to remove this video?"}
          confirmTitle={"Delete"}
          cancelTitle={"Cancel"}
          handleConfirm={() => {
            const payload = {
              profileId: profile.id,
              profileVideoId: selectedVideo.id
            }
            dispatch(removeProfileVideo(payload))
              .then(() => {
                setShowRemoveModal(false);
                setSelectedVideo(null)
              })
              .catch(() => {
                setShowRemoveModal(false);
              })
          }}
          handleCancel={() => {
            setShowRemoveModal(false);
            setSelectedVideo(null);
          }}
        />
      )}
    </>
  );
};

export default ProfileVideo;
