import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { relations, urltoFile } from "../../../../utils";
import AvatarUploadModal from "../upload/AvatarUploadModal";
import DatePickerField from "./DatePicker";
import Headline from "./Headline";
import BlankAvatarSVG from "../../../../assets/project/detail/blank.svg";

const ProfileModal = ({ onClose, isLoading, onSubmit }) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [preview, setPreview] = useState(null);
  const [avatarFile, setFile] = useState(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter the first name.")
      .min(2, "First name must be at least 2 characters long."),
    middleName: Yup.string().min(
      2,
      "Middle name must be at least 2 characters long."
    ),
    lastName: Yup.string()
      .required("Please enter the last name.")
      .min(2, "Last name must be at least 2 characters long."),
    title: Yup.string().min(2, "Title must be at least 2 characters long."),
    bio: Yup.string().min(
      10,
      "The obituary text must be at least 10 characters long."
    ),
    birthDay: Yup.string().required("Please select the birth date."),
    deathDay: Yup.string().required("Please select the death date."),
    city: Yup.string().min(2, "The city must be at least 2 characters long."),
    state: Yup.string().min(2, "The state must be at least 2 characters long."),
  });

  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal font-WorkSans">
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -lg:w-[90%] lg:w-[1100px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">Create new profile</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={onClose}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center p-8">
          <Formik
            initialValues={{
              firstName: "",
              middleName: "",
              lastName: "",
              title: "",
              relation: "",
              avatar: null,
              isVeteran: false,
              headline: "",
              isIncludeHeadline: false,
              obituaryLink: "",
              bio: "",
              birthDay: null,
              deathDay: null,
              city: "",
              state: "",
              quote: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (values.isIncludeHeadline) {
                values.headline = "";
              }
              if (avatarFile && preview) {
                urltoFile(preview, avatarFile.name, avatarFile.type).then(
                  (croppedFile) => {
                    values.avatar = croppedFile;
                    onSubmit(values, setSubmitting);
                  }
                );
              } else {
                onSubmit(values, setSubmitting);
              }
            }}
          >
            {() => (
              <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
                <Headline title="Personal Details" />
                <div className="text-[12px] text-gray-500">
                  Start by entering as much info as you can about your loved
                  one. You will have a chance to update this later.
                </div>
                <div className="grid grid-cols-3 -sm:grid-cols-1 gap-4">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      First name: *
                    </label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="middleName"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Middle name:
                    </label>
                    <Field
                      type="text"
                      id="middleName"
                      name="middleName"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="middleName"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Last name: *
                    </label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
                  <div>
                    <label
                      htmlFor="title"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Title:
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      placeholder="Example Jr - Sr"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="relation"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Relationship:
                    </label>
                    <Field
                      as="select"
                      id="relation"
                      name="relation"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    >
                      <option value="">-- Select an option --</option>
                      {relations.map((relation, index) => {
                        return (
                          <option value={relation} key={index}>
                            {relation}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4 items-center">
                  <div>
                    <div className="relative w-[120px] -md:w-[100px] h-[120px] -md:h-[100px] rounded-full">
                      <img
                        src={preview ? preview : BlankAvatarSVG}
                        className="rounded-full w-full h-full object-cover border-[3px]"
                      />
                    </div>
                    <label
                      htmlFor="avatar"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Profile Picture:
                    </label>
                    <div className="flex flex-row items-center space-x-4">
                      <button
                        type="button"
                        className="max-w-x rounded-lg py-2 px-6 border border-[#eef0f2] bg-[#eef0f2] transition-colors text-[14px]"
                        onClick={() => setShowUploadModal(true)}
                      >
                        Upload Avatar
                      </button>
                      <span className="text-gray-600 text-sm block">
                        {avatarFile ? avatarFile.name : "No file chosen"}
                      </span>
                    </div>
                  </div>
                  <div className="pt-5">
                    <label className="flex flex-row items-center space-x-2">
                      <Field type="checkbox" id="isVeteran" name="isVeteran" />
                      <span className="text-gray-600 text-sm block cursor-pointer">
                        Is a Veteran?
                      </span>
                    </label>
                  </div>
                </div>
                <Headline title="Headline text" />
                <div>
                  <label
                    htmlFor="headline"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Text or phrase:
                  </label>
                  <Field
                    type="text"
                    id="headline"
                    name="headline"
                    placeholder="In loving memory of"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <div className="text-[12px] mt-2 text-gray-500">
                    This headline text is the one that shows above the name of
                    the person. If this field is null, the headline text won’t
                    be added.
                  </div>
                  <label className="flex flex-row items-center space-x-2 pt-4">
                    <Field
                      type="checkbox"
                      id="isIncludeHeadline"
                      name="isIncludeHeadline"
                    />
                    <span className="text-gray-600 text-sm block cursor-pointer">
                      Don't include headline text
                    </span>
                  </label>
                </div>
                <Headline title="Obituary Information" />
                <div>
                  <label
                    htmlFor="obituaryLink"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Link to Obituary:
                  </label>
                  <Field
                    type="text"
                    id="obituaryLink"
                    name="obituaryLink"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="obituaryLink"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="bio"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Bio information:
                  </label>
                  <Field
                    component="textarea"
                    id="bio"
                    name="bio"
                    rows="3"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="bio"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <Headline title="Lifetime" />
                <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
                  <div className="w-full">
                    <label
                      htmlFor="birthDay"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Birth date: *
                    </label>
                    <DatePickerField
                      name="birthDay"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="birthDay"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="deathDay"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      Death date: *
                    </label>
                    <DatePickerField
                      name="deathDay"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="deathDay"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                </div>
                <Headline title="Location Details" />
                <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
                  <div>
                    <label
                      htmlFor="city"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      City:
                    </label>
                    <Field
                      type="text"
                      id="city"
                      name="city"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="state"
                      className="text-gray-600 text-sm block mb-1"
                    >
                      State:
                    </label>
                    <Field
                      type="text"
                      id="state"
                      name="state"
                      className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="text-red-500 !text-[12px]"
                    />
                  </div>
                </div>
                <Headline title="Quote Section" />
                <div>
                  <label
                    htmlFor="quote"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Text or phrase:
                  </label>
                  <Field
                    component="textarea"
                    id="quote"
                    name="quote"
                    rows="3"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                    placeholder={`"Never let the fear of striking out keep you from playing the game." - Babe Ruth`}
                  />
                  <div className="text-[12px] mt-1 text-gray-500">
                    This headline text is the one that shows above the name of
                    the person.
                  </div>
                </div>

                <div className="flex w-full p-4 items-center justify-center font-WorkSans">
                  <button
                    type="submit"
                    className="w-full max-w-xs bg-[#10405A] text-white rounded-lg py-3 hover-bg-indigo-600 transition-colors text-[14px]"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Create profile"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showUploadModal && (
        <AvatarUploadModal
          title={"Crop Image"}
          closeModal={() => setShowUploadModal(false)}
          handleUpload={() => setShowUploadModal(false)}
          preview={preview}
          setPreview={setPreview}
          setFile={setFile}
        />
      )}
    </div>
  );
};

export default ProfileModal;
