import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQRCodes } from "../../actions/qr";
import SvgHeartQrForPDF from "./SvgHeartQrForPDF";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const PDFDownload = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { qrs: result } = useSelector((state) => state.qr);

  useEffect(() => {
    fetchQRcodes();
  }, []);

  const fetchQRcodes = () => {
    setIsLoading(true);
    dispatch(getQRCodes(501, 1))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="w-full h-full">
      <div className="flex flex-col justify-center items-center">
        {result.qrCodes.length > 0 &&
          result.qrCodes.map((row, index) => {
            return (
              <div key={index} className="w-[550px] h-[100vh] flex flex-row justify-center items-center">
                <SvgHeartQrForPDF
                  url={`${BACKEND_URL}/uploads/qrcode/${row.id}.svg`}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PDFDownload;
