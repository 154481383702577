import api from "./api";

const create = (payload) => {
  return api.post("/tribute/create", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getTributes = (profileId) => {
  return api.get("/tribute/getTributes", {
    params: { profileId },
  });
};

const getAllForAdmin = (pageSize, pageNumber) => {
  return api.get("/tribute/getAllForAdmin", {
    params: {
      pageSize, pageNumber
    }
  });
};

const approve = (payload) => {
  return api.post("/tribute/approve", payload);
};

const getTributesByUser = () => {
  return api.get("/tribute/getTributes");
};

const update = (payload) => {
  return api.post("/tribute/update", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const removeTribute = (payload) => {
  return api.post("/tribute/remove", payload);
};

const removeTributeImage = (payload) => {
  return api.post("/tribute/deleteTributeImage", payload);
};



export default {
  create,
  getTributes,
  update,
  removeTribute,
  removeTributeImage,
  getTributesByUser,
  getAllForAdmin,
  approve
};
