import {
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAIL,
  PROFILE_GETALL_SUCCESS,
  PROFILE_GETALL_FAIL,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAIL,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_IMAGE_SUCCESS,
  PROFILE_IMAGE_FAIL,
  PROFILE_VIDEO_SUCCESS,
  PROFILE_VIDEO_FAIL,
  PROFILE_LINK_SUCCESS,
  PROFILE_LINK_FAIL,
  SHARE_PROFILE_GET_SUCCESS,
  SHARE_PROFILE_GET_FAIL,
  TRIBUTE_GETALL_SUCCESS,
  TRIBUTE_GETALL_FAIL,
  SET_MESSAGE,
} from "./types";
import ProfileService from "../services/profile.service";

export const createProfile = (payload) => (dispatch) => {
  return ProfileService.create(payload).then(
    (response) => {
      dispatch({ type: PROFILE_CREATE_SUCCESS });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const get = (profileId) => (dispatch) => {
  return ProfileService.get(profileId).then(
    (response) => {
      dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: response.data,
      });
      if (response.data.message) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message,
          },
        });
      }
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getProfiles = () => (dispatch) => {
  return ProfileService.getProfiles().then(
    (response) => {
      dispatch({
        type: PROFILE_GETALL_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      console.log("error: ", error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const update = (payload) => (dispatch) => {
  return ProfileService.update(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: response.data.profile,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: response.data.success,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const updateAvatar = (payload) => (dispatch) => {
  return ProfileService.updateAvatar(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: response.data.profile,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: response.data.success,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const updateBanner = (payload) => (dispatch) => {
  return ProfileService.updateBanner(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        payload: response.data.profile,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: response.data.success,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const createProfileImage = (payload) => (dispatch) => {
  return ProfileService.createProfileImage(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_IMAGE_SUCCESS,
        payload: response.data.profileImages,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_IMAGE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getProfileImages = (profileId) => (dispatch) => {
  return ProfileService.getProfileImage(profileId).then(
    (response) => {
      dispatch({
        type: PROFILE_IMAGE_SUCCESS,
        payload: response.data.profileImages,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_IMAGE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const updateProfileImage = (payload) => (dispatch) => {
  return ProfileService.updateProfileImage(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_IMAGE_SUCCESS,
        payload: response.data.profileImages,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_IMAGE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const removeProfileImage = (payload) => (dispatch) => {
  return ProfileService.removeProfileImage(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_IMAGE_SUCCESS,
        payload: response.data.profileImages,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_IMAGE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const createProfileVideo = (payload) => (dispatch) => {
  return ProfileService.createProfileVideo(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_VIDEO_SUCCESS,
        payload: response.data.profileVideos,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_VIDEO_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getProfileVideos = (profileId) => (dispatch) => {
  return ProfileService.getProfileVideo(profileId).then(
    (response) => {
      dispatch({
        type: PROFILE_VIDEO_SUCCESS,
        payload: response.data.profileVideos,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_VIDEO_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const removeProfile = (profileId) => (dispatch) => {
  return ProfileService.removeProfile(profileId).then(
    (response) => {
      dispatch({
        type: PROFILE_GETALL_SUCCESS,
        payload: response.data.profiles,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const removeProfileVideo = (payload) => (dispatch) => {
  return ProfileService.removeProfileVideo(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_VIDEO_SUCCESS,
        payload: response.data.profileVideos,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_VIDEO_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};


export const createProfileLink = (payload) => (dispatch) => {
  return ProfileService.createProfileLink(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_LINK_SUCCESS,
        payload: response.data.profileLinks,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_LINK_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getProfileLinks = (profileId) => (dispatch) => {
  return ProfileService.getProfileLink(profileId).then(
    (response) => {
      dispatch({
        type: PROFILE_LINK_SUCCESS,
        payload: response.data.profileLinks,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_LINK_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const removeProfileLink = (payload) => (dispatch) => {
  return ProfileService.removeProfileLink(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_LINK_SUCCESS,
        payload: response.data.profileLinks,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_LINK_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const updateProfileLink = (payload) => (dispatch) => {
  return ProfileService.updateProfileLink(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_LINK_SUCCESS,
        payload: response.data.profileLinks,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_LINK_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};


export const deactivateAdmin = (payload) => (dispatch) => {
  return ProfileService.deactivateAdmin(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: response.data,
      });
      if (response.data.message) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message,
          },
        });
      }
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const inviteAdmin = (payload) => (dispatch) => {
  return ProfileService.inviteAdmin(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: response.data,
      });
      if (response.data.message) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message,
          },
        });
      }
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getShareProfile = (profileId, userId) => (dispatch) => {
  return ProfileService.getShareProfile(profileId, userId).then(
    (response) => {
      dispatch({
        type: SHARE_PROFILE_GET_SUCCESS,
        payload: response.data,
      });
      if (response.data.message) {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            success: false,
            message: response.data.message,
          },
        });
      }
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: SHARE_PROFILE_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getTributes = () => (dispatch) => {
  return ProfileService.getTributes().then(
    (response) => {
      dispatch({
        type: TRIBUTE_GETALL_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const changeStatus = (payload) => (dispatch) => {
  return ProfileService.changeStatus(payload).then(
    (response) => {
      dispatch({
        type: PROFILE_CREATE_SUCCESS
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: response.data.success,
          message: response.data.message,
        },
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: PROFILE_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};