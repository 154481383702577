import React, { useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get } from "../../actions/qr";

const RequireMedallionLayout = ({ children }) => {
  const { medallionId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (medallionId) {
      dispatch(get(medallionId)).then((res) => {
        if (res) {
          history.push(`/share/${res.profileId}`);
        } else {
          if (!currentUser) {
            history.push("/login", { from: history.location.pathname });
          }
        }
      });
    }
  }, [medallionId]);

  return <>{children}</>;
};

export default RequireMedallionLayout;
