import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";

const ConfirmModalQR = ({
  title,
  confirmTitle,
  cancelTitle,
  handleConfirm,
  handleCancel,
  isLoading,
}) => {
  const [count, setCount] = useState(0);
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[96%] md:w-[450px] font-WorkSans">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">{title}</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={handleCancel}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-start text-center font-RNSSanzBold py-12 px-8">
          <span className="text-[12px]">QR code count (max 100)</span>
          <input
            type="number"
            value={count}
            onChange={(e) => setCount(e.target.value)}
            className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
          />
        </div>
        <div className="flex flex-row justify-center items-center py-4 font-SenSans text-[14px] space-x-2 w-full border-t border-t-gray-200">
          <div
            className="w-[100px] py-2 flex flex-row justify-center items-center border border-[#10405A] rounded-lg bg-white text-[#10405A] cursor-pointer"
            onClick={handleCancel}
          >
            {cancelTitle}
          </div>
          <div
            className="w-[100px] py-2 flex flex-row justify-center items-center border border-[#10405A] rounded-lg bg-[#10405A] text-white cursor-pointer"
            onClick={() => {
              if (count > 0 && count <= 100) {
                handleConfirm(count)
              }
            }}
          >
            {isLoading ? (
              <CgSpinner className={`text-white text-[20px] animate-spin`} />
            ) : (
              confirmTitle
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModalQR;
