import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAdminLayout = ({ children }) => {
  const history = useHistory();
  const { user: currentUser } = useSelector(state => state.auth);

  if (!currentUser) {
    return <Redirect to={{ pathname: '/login', state: { from: history.location.pathname } }} />
  }

  if (currentUser.role !== 'admin') {
    return <Redirect to="/dashboard/medallion/profile" />
  }

  return (
    <>
      {children}
    </>
  )
}

export default RequireAdminLayout;