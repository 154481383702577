import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTributes } from "../../../actions/profile";
import moment from "moment";
import BlankAvatarSVG from "../../../assets/project/detail/blank.svg";

const MyPost = () => {
  const dispatch = useDispatch();
  const { tributes } = useSelector((state) => state.tribute);
  useEffect(() => {
    dispatch(getTributes())
      .then(() => {})
      .catch(() => {});
  }, []);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;

  return (
    <div className="w-full">
      <div className="flex flex-col justify-start items-start space-y-4 w-full">
        {tributes.length > 0 &&
          tributes.map((tribute, index) => {
            return (
              <div
                key={index}
                className="w-full flex flex-col justify-start items-start space-y-4 p-8 rounded-lg font-WorkSans mt-4"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div className="w-full flex flex-row justify-between items-center border-b pb-4">
                  <div className="flex flex-row justify-start items-center space-x-2">
                    <img
                      src={
                        tribute?.profile?.avatar
                          ? `${BACKEND_URL}/uploads/profiles/avatar/${tribute.profile.avatar}`
                          : BlankAvatarSVG
                      }
                      className="w-[70px] h-[70px] rounded-full border"
                    />
                    <div className="flex flex-col justify-start items-start">
                      <span className="font-bold text-[20px] -sm:text-[14px]">
                        {tribute.profile?.firstName +
                          " " +
                          tribute.profile?.middleName +
                          " " +
                          tribute.profile?.lastName}
                      </span>
                      <span className="text-[12px]">
                        {moment(tribute.createdAt).startOf("seconds").fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-start items-start space-y-2">
                  <p className="font-[600] text-[20px] -sm:text-[16px]">{tribute.title}</p>
                  <p className="text-[16px] -sm:text-[14px]">{tribute.description}</p>
                </div>
              </div>
            );
          })}
      </div>
      {tributes.length === 0 && (
        <div className="font-WorkSans text-center w-full">
          There is no post.
        </div>
      )}
    </div>
  );
};

export default MyPost;
