import React, { useEffect, useState } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import MainLayout from "./MainLayout";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { getProfiles } from "../actions/profile";
import { connectMedallion, createActiveProfile, get } from "../actions/qr";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import BlankAvatarSVG from "../assets/project/detail/blank.svg";
import DatePickerField from "./dashboard/components/medallion/DatePicker";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const Card = ({ card, onClick }) => {
  const avatar = card?.avatar
    ? `${BACKEND_URL}/uploads/profiles/avatar/${card.avatar}`
    : BlankAvatarSVG;

  return (
    <div className="w-full flex flex-col justify-center items-center space-y-4">
      <div className="w-[190px] h-[190px]">
        <img src={avatar} className="rounded-full w-full h-full" />
      </div>
      <p className="text-[18px] -sm:text-[16px] font-[600] font-WorkSans pt-2 text-black">
        {`${card.firstName} ${card.middleName} ${card.lastName}`}
      </p>
      <div
        className="flex flex-row justify-center items-center space-x-4 bg-[#10405A] py-2 px-12 rounded-lg text-white cursor-pointer"
        onClick={onClick}
      >
        <span className="text-[16px] -sm:text-[14px]">Select</span>
      </div>
    </div>
  );
};

const ConnectMedallion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { profiles } = useSelector((state) => state.profile);
  const [isLoading, setIsLoading] = useState(false);
  const { medallionId } = useParams();

  useEffect(() => {
    if (currentUser) {
      dispatch(getProfiles());
    }
  }, [currentUser]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter the first name.")
      .min(2, "First name must be at least 2 characters long."),
    middleName: Yup.string().min(
      2,
      "Middle name must be at least 2 characters long."
    ),
    lastName: Yup.string()
      .required("Please enter the last name.")
      .min(2, "Last name must be at least 2 characters long."),
    birthDay: Yup.string().required("Please select the birth date."),
    deathDay: Yup.string().required("Please select the death date."),
  });

  if (!currentUser) {
    return null;
  }

  return (
    <MainLayout>
      <div className="flex flex-col justify-center items-center bg-white p-12 -sm:p-8 rounded-lg max-w-[500px] -sm:w-full mx-auto font-WorkSans text-gray-600">
        <div className="text-[32px] font-[600] mb-2">Activate Medallion</div>
        <div className="text-[15px] border-b pb-4 w-full text-center">
          Thank you for purchasing a My Lasting Memories medallion
        </div>
        <div className="text-[15px] pb-4 w-full text-center my-4 px-6">
          You can activate one of the accounts below to be attached to this
          medallion.
        </div>
        {(profiles.length > 0 && (
          <div className="w-full border rounded-lg px-4 py-10">
            <Carousel
              axis="horizontal"
              className="w-full relative"
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    onClick={clickHandler}
                    className={`top-0 bottom-0 left-0 flex justify-center items-center z-20 opacity-50 hover:opacity-100 cursor-pointer ${
                      hasPrev ? "absolute" : "hidden"
                    }`}
                  >
                    <FaChevronLeft className="text-[30px]" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    onClick={clickHandler}
                    className={`top-0 bottom-0 right-0 flex justify-center items-center z-20 opacity-50 hover:opacity-100 cursor-pointer ${
                      hasNext ? "absolute" : "hidden"
                    }`}
                  >
                    <FaChevronRight className="text-[30px]" />
                  </div>
                );
              }}
            >
              {profiles.length > 0 &&
                profiles.map((profile, index) => {
                  return (
                    <Card
                      key={index}
                      card={profile.profile}
                      onClick={() => {
                        const payload = {
                          profileId: profile.profile.id,
                          qrCodeId: medallionId,
                        };
                        dispatch(connectMedallion(payload)).then(() => {
                          history.push(`/share/${profile.profile.id}`);
                        });
                      }}
                    />
                  );
                })}
            </Carousel>
          </div>
        )) || (
          <div className="text-[16px] pb-4 w-full text-center my-4 px-6 font-bold">
            There is no profile, please create new one with below form.
          </div>
        )}
        <div className="text-[15px] border-t pt-4 w-full mt-8">
          Complete the form below to create a new profile.
        </div>
        <Formik
          initialValues={{
            firstName: "",
            middleName: "",
            lastName: "",
            birthDay: null,
            deathDay: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values: ", values);
            const payload = {
              qrCodeId: medallionId,
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              birthDay: values.birthDay,
              deathDay: values.deathDay,
            };
            dispatch(createActiveProfile(payload))
              .then(() => {
                history.push("/dashboard/medallion/profile");
              })
              .catch(() => {});
          }}
        >
          {() => (
            <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    First name: *
                  </label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="middleName"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Middle name:
                  </label>
                  <Field
                    type="text"
                    id="middleName"
                    name="middleName"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="middleName"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Last name: *
                  </label>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="birthDay"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Birth date: *
                  </label>
                  <DatePickerField
                    name="birthDay"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="birthDay"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="deathDay"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Death date: *
                  </label>
                  <DatePickerField
                    name="deathDay"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="deathDay"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
              </div>
              <div className="flex w-full py-4 items-center justify-center font-WorkSans">
                <button
                  type="submit"
                  className="w-full bg-[#10405A] text-white rounded-lg py-3 hover-bg-indigo-600 transition-colors text-[14px]"
                  disabled={isLoading}
                >
                  {isLoading ? "Processing..." : "Create New Profile"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MainLayout>
  );
};

export default ConnectMedallion;
