import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import message from "./message";
import profile from "./profile";
import group from "./group";
import tribute from "./tribute";
import favorite from "./favorite";
import qr from "./qr";

export default combineReducers({
  auth,
  user,
  message,
  profile,
  group,
  tribute,
  favorite,
  qr,
});
