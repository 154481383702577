import {
  TRIBUTE_CREATE_SUCCESS,
  TRIBUTE_CREATE_FAIL,
  TRIBUTE_GETALL_SUCCESS,
  TRIBUTE_GETALL_FAIL,
  TRIBUTE_UPDATE_SUCCESS,
  TRIBUTE_UPDATE_FAIL,
  TRIBUTE_REMOVE_SUCCESS,
  TRIBUTE_REMOVE_FAIL,
  TRIBUTE_GETALL_ADMIN_SUCCESS,
  TRIBUTE_GETALL_ADMIN_FAIL,
  SET_MESSAGE
} from "./types";
import TributeService from "../services/tribute.service";

export const createTribute = (payload) => (dispatch) => {
  return TributeService.create(payload).then(
    (response) => {
      dispatch({ type: TRIBUTE_CREATE_SUCCESS });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getTributes = (profileId) => (dispatch) => {
  return TributeService.getTributes(profileId).then(
    (response) => {
      dispatch({
        type: TRIBUTE_GETALL_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getAllForAdmin = (pageSize, pageNumber) => (dispatch) => {
  return TributeService.getAllForAdmin(pageSize, pageNumber).then(
    (response) => {
      if (response.data.success) {
        dispatch({
          type: TRIBUTE_GETALL_ADMIN_SUCCESS,
          payload: response.data.data,
        });
        return Promise.resolve();
      } else {
        dispatch({ type: TRIBUTE_GETALL_ADMIN_FAIL });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_GETALL_ADMIN_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const approve = (payload) => (dispatch) => {
  return TributeService.approve(payload).then(
    (response) => {
      dispatch({
        type: TRIBUTE_UPDATE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const update = (payload) => (dispatch) => {
  return TributeService.update(payload).then(
    (response) => {
      dispatch({
        type: TRIBUTE_UPDATE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const remove = (payload) => (dispatch) => {
  return TributeService.removeTribute(payload).then(
    (response) => {
      dispatch({
        type: TRIBUTE_REMOVE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_REMOVE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const removeTributeImage = (payload) => (dispatch) => {
  return TributeService.removeTributeImage(payload).then(
    (response) => {
      dispatch({
        type: TRIBUTE_REMOVE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: TRIBUTE_REMOVE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};