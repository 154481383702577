import api from "./api";

const updateUserAvatar = (payload) => {
  return api.post("/user/updateAvatar", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const updateUsername = (payload) => {
  return api.post("/user/updateUsername", payload);
}

const updatePassword = (payload) => {
  return api.post("/user/updatePassword", payload);
}

export default {
  updateUserAvatar,
  updateUsername,
  updatePassword
};