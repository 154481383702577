import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LogoSVG from '../../assets/auth/logo.svg';
import PasswordPNG from '../../assets/auth/password.png';
import CheckButton from "react-validation/build/button";
import { CgSpinner } from 'react-icons/cg';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { forgotPassword, resetPassword } from "../../actions/auth";
import { required, validEmail, vpassword, vpasswordConfirm } from '../../utils';

const InputEmail = () => {
  const dispatch = useDispatch();
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(forgotPassword(email))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        })
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className='flex flex-col justify-center items-center space-y-4 max-w-[340px] -sm:w-[100%] -sm:px-4'>
      <span className='font-RNSSanzMedium text-[16px] text-[#282828] w-[100%] text-center'>
        Please enter your email address below. Further instructions on how to reset your password will be sent to your email.
      </span>
      <Form onSubmit={handleForgotPassword} ref={form} className="flex flex-col justify-center itesm-center">
        <div className='form-group'>
          <label htmlFor='email' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>
            Email
          </label>
          <Input
            type="text"
            className="form-control !bg-white"
            name="email"
            value={email}
            onChange={onChangeEmail}
            validations={[required, validEmail]}
            placeholder={'Email'}
          />
        </div>
        <div className='form-group w-[100%] flex flex-row justify-center'>
          <button className='!w-[150px] !h-[46px] font-WorkSans !text-[18px]'>
            {isLoading ?
              <CgSpinner className={`text-white text-[30px] animate-spin`} /> :
              <span>Submit</span>
            }
          </button>
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  )
}

const InputPassword = ({ resetCode }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const form = useRef();
  const checkBtn = useRef();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  }

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  }

  const handleResetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(resetPassword(resetCode, newPassword))
        .then(() => {
          setIsLoading(false);
          history.push('/login');
        })
        .catch(() => {
          setIsLoading(false);
          history.push('/password_reset');
        })
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className='flex flex-col justify-center items-center space-y-4 max-w-[340px] -sm:w-[100%] -sm:px-4'>
      <span className='font-RNSSanzMedium text-[16px] text-[#282828] w-[100%] text-center'>
        Here you can change your password. Please type it twice to avoid any typos.
      </span>
      <Form onSubmit={handleResetPassword} ref={form} className="flex flex-col justify-center itesm-center">
        <div className='form-group'>
          <label htmlFor='password' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>
            New Password
          </label>
          <Input
            type="password"
            className="form-control !bg-white"
            name="password"
            value={newPassword}
            onChange={onChangeNewPassword}
            validations={[required, vpassword]}
            placeholder={'New password'}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='confirmPassword' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>
            Confirm New Password
          </label>
          <Input
            type="password"
            className="form-control !bg-white"
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            validations={[required, vpasswordConfirm]}
            placeholder={'Confirm New Password'}
          />
        </div>
        <div className='form-group w-[100%] flex flex-row justify-center'>
          <button className='!w-[150px] !h-[46px] font-WorkSans !text-[18px]'>
            {isLoading ?
              <CgSpinner className={`text-white text-[30px] animate-spin`} /> :
              <span>Submit</span>
            }
          </button>
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  )
}

const PasswordReset = (props) => {
  const history = useHistory();
  const { resetCode } = props.match.params;
  const { user: currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    if (currentUser) {
      history.push("/dashboard/medallion/profile");
    }
  }, [currentUser])

  return (
    <div className='flex flex-col justify-start items-center max-w-[1750px] mx-auto min-w-[350px] p-8'>
      {/* <div className='w-[100%] flex flex-row justify-start items-center'>
        <img src={LogoSVG} className="-sm:w-[200px] sm:w-[250px] cursor-pointer" onClick={() => history.push('/')} />
      </div> */}
      <div className='flex flex-col justify-center items-center w-[100%] h-[calc(100vh-280px)] min-h-[500px] space-y-4'>
        <img src={PasswordPNG} className="w-[100px]" />
        {resetCode ?
          <InputPassword resetCode={resetCode} /> :
          <InputEmail />
        }
      </div>
    </div>
  )
}

export default PasswordReset;