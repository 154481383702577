import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites, favorite } from "../../../actions/favorite";
import FavoriteCard from "./medallion/FavoriteCard";

const MyFavorites = () => {
  const dispatch = useDispatch();
  const { favorites } = useSelector((state) => state.favorite);
  useEffect(() => {
    dispatch(getFavorites());
  }, []);

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 -lg:grid-cols-2 -md:grid-cols-1 gap-8 w-full px-3">
        {favorites.length > 0 &&
          favorites.map((favoriteProfile, index) => {
            return (
              <FavoriteCard
                key={index}
                profile={favoriteProfile?.profile}
                handleUnFavorite={() =>
                  dispatch(
                    favorite({
                      profileId: favoriteProfile?.profile.id,
                      isFavorite: false,
                    })
                  ).then(() => {
                    dispatch(getFavorites());
                  })
                }
              />
            );
          })}
      </div>
      {favorites.length === 0 && (
        <div className="font-WorkSans text-center w-full">
          There is no favorited medallions.
        </div>
      )}
    </div>
  );
};

export default MyFavorites;
