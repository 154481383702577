import { isEmail } from "validator";

export const required = (value) => {
  if (!value) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        This field is required!
      </div>
    );
  }
};

export const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

export const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px] !w-[165px] -sm:!w-[250px]" role="alert">
        Name must be between 3 and 20 characters.
      </div>
    );
  }
};

export const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export const vpasswordConfirm = (value, props, components) => {
  if (value !== components['password'][0].value) {
    return (
      <div className="text-[red] font-RNSSanzMedium text-[12px] pl-2 tracking-[0.5px]" role="alert">
        Password should be match.
      </div>
    );
  }
}

export const urltoFile = (url, filename, mimeType) => {
  return (fetch(url)
    .then(function (res) { return res.arrayBuffer(); })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
  );
}

export const relations = [
  'Aunt',
  'Brother',
  'Cousin',
  'Daughter',
  'Father',
  'Granddaughter',
  'Grandfather',
  'Grandmother',
  'Grandson',
  'Grand grandfater',
  'Grand grandmother',
  'Husband',
  'Mother',
  'Nephew',
  'Niece',
  'Sister',
  'Son',
  'Uncle',
  'Wife'
]