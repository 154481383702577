import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaMedal } from "react-icons/fa";
import { BsFillShareFill, BsHeartFill } from "react-icons/bs";
import { getFavorite, favorite } from "../../actions/favorite";
import BlankAvatarSVG from "../../assets/project/detail/blank.svg";
import BlankBannerIMG from "../../assets/dashboard/banner.png";
import ShareModal from "./ShareModal";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const ShareBanner = ({ profile, isFavorited }) => {
  const [openShareModal, setOpenShareModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <div className="w-full mb-[100px]">
      <div className="w-full">
        <img
          src={
            profile?.banner
              ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.banner}`
              : BlankBannerIMG
          }
          className="w-full rounded-t-lg aspect-[4/1.2] object-cover"
        />
      </div>
      <div className="bg-white flex flex-row justify-start items-start px-12 -md:px-4">
        <div className="flex flex-col justify-center items-center space-y-4 transform translate-y-[-90px] -md:translate-y-[-50px]">
          <div className="relative w-[180px] -md:w-[100px] h-[180px] -md:h-[100px] rounded-full">
            <img
              src={
                profile?.avatar
                  ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.avatar}`
                  : BlankAvatarSVG
              }
              className="rounded-full w-full h-full object-cover border-[3px]"
            />
          </div>
          {profile?.isVeteran && (
            <div className="flex flex-row justify-center items-center space-x-2 -sm:text-[13px]">
              <span>Veteran</span>
              <FaMedal />
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-row -md:flex-col justify-between -md:space-y-4 items-start p-8 font-WorkSans">
          <div className="flex flex-col justify-start items-start space-y-2">
            <p className="font-bold text-gray-400 text-[20px] -sm:text-[16px]">
              {profile?.headline}
            </p>
            <p className="text-[28px] font-[600] -sm:text-[18px]">
              <span>{`${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}</span>
            </p>
            <p className="text-[15px] text-gray-700 -sm:text-[13px]">
              <span className="font-[600]">Lifetime: </span>
              <span>
                {`${moment(profile?.birthDay).format(
                  "MMM DD, YYYY"
                )} - ${moment(profile?.deathDay).format("MMM DD, YYYY")}`}
              </span>
            </p>
            <p className="italic text-gray-500 -sm:text-[13px]">
              {profile?.quote}
            </p>
          </div>
          <div className="flex flex-row justify-start items-start space-x-2">
            <div
              className="px-3 py-[10px] rounded-lg bg-gray-300 flex justify-center items-center cursor-pointer -sm:text-[13px]"
              onClick={() => {
                setOpenShareModal(true);
              }}
            >
              <BsFillShareFill className="text-[24px] text-white " />
            </div>
            <div
              className={`px-3 pt-3 pb-2 rounded-lg bg-gray-300 flex justify-center items-center cursor-pointer`}
              onClick={() => {
                if (currentUser) {
                  dispatch(
                    favorite({
                      profileId: profile.id,
                      isFavorite: isFavorited ? false : true,
                    })
                  )
                    .then(() => {
                      dispatch(getFavorite({ profileId: profile.id }));
                    })
                    .catch(() => {});
                } else {
                  history.push("/login");
                }
              }}
            >
              <BsHeartFill
                className={`text-[24px] ${
                  isFavorited ? "text-[#10405A]" : "text-white"
                } `}
              />
            </div>
          </div>
        </div>
      </div>
      {openShareModal && (
        <ShareModal
          title={"Share the profile"}
          closeModal={() => setOpenShareModal(false)}
          shareUrl={`https://app.mylastingmemories.com/share/${profile.id}`}
        />
      )}
    </div>
  );
};

export default ShareBanner;
