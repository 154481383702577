import api from './api';

const create = (payload) => {
  return api.post("/qr/create", payload)
}

const getQRCodes = (pageSize, pageNumber) => {
  return api.get("/qr/getQRCodes", {
    params: {
      pageSize, pageNumber
    }
  });
}

const get = (qrCodeId) => {
  return api.get("/qr/get", {
    params: {
      qrCodeId
    }
  })
}

const connectMedallion = (payload) => {
  return api.post("/qr/update", payload);
}

const createActiveProfile = (payload) => {
  return api.post("/qr/createActiveProfile", payload)
}

const disconnectMedallion = (payload) => {
  return api.post("/qr/disconnect", payload)
}

export default {
  get,
  create,
  getQRCodes,
  connectMedallion,
  createActiveProfile,
  disconnectMedallion
}