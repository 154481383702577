import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REFRESH_TOKEN,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_NAME_SUCCESS,
  USER_UPDATE_NAME_FAIL,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user, verify: { email: null, firstname: null } }
  : { isLoggedIn: false, user: null, verify: { email: null, firstname: null } };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        verify: payload,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        verify: null,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: { ...user, accessToken: payload },
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: { ...user, avatar: payload }
      }
    case USER_UPDATE_FAIL:
      return {
        ...state,
      }
    case USER_UPDATE_NAME_SUCCESS:
      return {
        ...state,
        user: {
          ...user,
          firstname: payload.firstname,
          lastname: payload.lastname
        }
      }
    case USER_UPDATE_NAME_FAIL:
      return {
        ...state
      }
    default:
      return state;
  }
}