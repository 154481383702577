import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "./helpers/history";
import Landing from "./components/landing";
import Home from "./components/Home";
import Dashboard from "./components/dashboard";
import Profile from "./components/profiles";
import NotFound from "./components/NotFound";
import Confirmation from "./components/auth/Confirmation";
import PasswordReset from "./components/auth/PasswordReset";
import Share from "./components/share";
import ConnectMedallion from "./components/ConnectMedallion";
import QR from "./components/qr";
import RequireAuthLayout from "./components/auth/RequireAuthLayout";
import RequireAdminLayout from "./components/auth/RequireAdminLayout";
import RequireUserLayout from "./components/auth/RequireUserLayout";
import RequireMedallionLayout from "./components/auth/RequireMedallionLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import TributeManagement from "./components/qr/TributeManagement";
import PDFDownload from "./components/qr/PDFDownload";

const Notification = () => {
  const { message } = useSelector((state) => state.message);
  useEffect(() => {
    if (message && message.id) {
      if (message.success)
        toast.success(message.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
        });
      else
        toast.error(message.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
        });
    }
  }, [message && message.id]);
  return <></>;
};

const App = () => {
  return (
    <>
      <Router history={history}>
        <RequireAuthLayout>
          <Switch>
            <Route exact path={["/login", "/register"]} component={Home} />
            <Route
              exact
              path={["/confirmation", "/confirmation/:confirmationCode"]}
              component={Confirmation}
            />
            <Route
              exact
              path={["/password_reset", "/password_reset/:resetCode"]}
              component={PasswordReset}
            />
            <Route exact path="/" component={Landing} />
            <Route
              path="/dashboard"
              component={(props) => (
                <RequireUserLayout {...props}>
                  <Dashboard {...props} />
                </RequireUserLayout>
              )}
            />
            <Route
              path="/profile/:profileId"
              component={(props) => (
                <RequireUserLayout {...props}>
                  <Profile {...props} />
                </RequireUserLayout>
              )}
            />
            <Route
              path="/medallion/:medallionId"
              component={(props) => (
                <RequireMedallionLayout {...props}>
                  <ConnectMedallion {...props} />
                </RequireMedallionLayout>
              )}
            />
            <Route
              path="/admin/qr-management"
              component={(props) => (
                <RequireAdminLayout {...props}>
                  <QR {...props} />
                </RequireAdminLayout>
              )}
            />
            <Route
              path="/admin/tribute-management"
              component={(props) => (
                <RequireAdminLayout {...props}>
                  <TributeManagement {...props} />
                </RequireAdminLayout>
              )}
            />
            <Route path="/share/:profileId" component={Share} />
            <Route
              path="/pdf-download"
              component={(props) => (
                <RequireUserLayout {...props}>
                  <PDFDownload {...props} />
                </RequireUserLayout>
              )}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </RequireAuthLayout>
      </Router>
      <ToastContainer />
      <Notification />
    </>
  );
};

export default App;
