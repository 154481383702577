import React, { useState, useEffect } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { FaPaperclip, FaTrashAlt } from "react-icons/fa";

const AttachedFileCard = ({ onRemove, fileImage, fileName, fileSize }) => {
  const [loadPercentage, setLoadPercentage] = useState(0);
  const [load, setLoad] = useState(0);
  const [fileType, setFileType] = useState('doc');

  const textEllipsis = (txt) => {
    if (txt.length > 19) {
      return txt.substr(0, 15) + '...' + txt.substr(txt.length - 4, txt.length);
    }
    return txt;
  };

  useEffect(() => {
    let splitedFile = fileName.split('.');
    let file_type = splitedFile[splitedFile.length - 1];
    if (['jpeg', 'jpg', 'png', 'svg', 'gif'].includes(file_type && file_type.toLowerCase())) {
      setFileType('img');
    }
  }, []);

  return (
    <div className="w-full h-16 mt-2 md:mt-0 p-2 bg-white border-dashed border border-gray-300 rounded-md">
      <div className="flex justify-between items-center align-middle">
        <div className="flex items-center">
          {
            fileType === 'img'
              ? (
                <img className="w-12 h-12 shadow rounded-md object-cover" src={fileImage} alt="file" />
              )
              : (
                <div className="w-12 h-12 shadow bg-white rounded-md flex flex-row justify-center items-center">
                  <FaPaperclip className='text-gray-800 text-[20px]' />
                </div>
              )
          }
          <div className="w-44 text-xs font-medium pl-3">
            <div className="font-bold text-black">{textEllipsis(fileName)}</div>
            <div className="">{(fileSize / 1000000).toFixed(2)} MB</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachedFileCard;