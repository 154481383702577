import {
  QR_CREATE_SUCCESS,
  QR_CREATE_FAIL,
  QR_GETALL_SUCCESS,
  QR_GETALL_FAIL,
  QR_GET_SUCCESS,
  QR_GET_FAIL
} from "../actions/types";

const initialState = {
  qr: null,
  qrs: {
    qrCodes: [],
    count: 0,
    hasMore: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case QR_CREATE_SUCCESS:
      return {
        ...state,
      };
    case QR_CREATE_FAIL:
      return {
        ...state,
      };
    case QR_GETALL_SUCCESS:
      return {
        ...state,
        qrs: payload,
      };
    case QR_GETALL_FAIL:
      return {
        ...state,
        qrs: {
          qrCodes: [],
          count: 0,
          hasMore: false,
        },
      };
    case QR_GET_SUCCESS:
      return {
        ...state,
        qr: payload
      }
    case QR_GET_FAIL:
      return {
        ...state,
        qr: null
      }
    default:
      return state;
  }
}
