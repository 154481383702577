import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LogoSVG from '../../assets/auth/logo.svg';
import VerifySVG from '../../assets/auth/verify.svg';
import { verifyUser, resend } from "../../actions/auth";
import { CgSpinner } from 'react-icons/cg';

const Confirmation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirmationText, setShowConfirmationText] = useState('');
  const [showLoginButton, setShowLoginButton] = useState(null);
  const { verify, user: currentUser } = useSelector(state => state.auth);
  const { confirmationCode } = props.match.params;

  useEffect(() => {
    if (currentUser) {
      history.push("/dashboard/medallion/profile");
    } else {
      if (confirmationCode) {
        dispatch(verifyUser(confirmationCode))
          .then(successMsg => {
            setShowConfirmationText(successMsg);
          })
          .catch(errorMsg => {
            setShowConfirmationText(errorMsg);
          })
        setShowLoginButton(true);
      } else {
        if (!verify.email) {
          history.push("/login");
        } else {
          setShowLoginButton(false);
          setShowConfirmationText(
            `We've sent you an email to ${verify.email}. Please check your inbox and click the link to activate your account.`
          )
        }
      }
    }
  }, [confirmationCode, verify, currentUser])

  const handleResendEmail = () => {
    if (verify) {
      setShowConfirmationText('');
      dispatch(resend(verify))
        .then(successMsg => {
          setShowConfirmationText('Your account has been verified');
        })
        .catch(errorMsg => {
          setShowConfirmationText(errorMsg);
        })
    }
  }

  return (
    <div className='flex flex-col justify-start items-center max-w-[1750px] mx-auto min-w-[350px] p-8'>
      <div className='w-[100%] flex flex-row justify-start items-center'>
        {/* <img src={LogoSVG} className="-sm:w-[200px] sm:w-[250px] cursor-pointer" onClick={() => history.push('/')} /> */}
      </div>
      <div className='flex flex-col justify-center items-center w-[100%] h-[calc(100vh-280px)] min-h-[500px] space-y-4'>
        <img src={VerifySVG} className="w-[100px]" />
        <div className='flex flex-col justify-center items-center space-y-4 max-w-[400px] -sm:w-[100%] -sm:px-4'>
          {showConfirmationText ?
            <span className='font-RNSSanzMedium text-[16px] text-[#282828] w-[100%] text-center'>
              {showConfirmationText}
            </span> :
            <CgSpinner className={`text-[#10405A] text-[40px] animate-spin`} />
          }
          {showLoginButton ?
            <a className='text-[#10405A] text-[18px] font-WorkSans cursor-pointer underline uppercase' onClick={() => history.push("/login")}>
              Back to login page
            </a> :
            <div className='px-8 py-3 bg-[#10405A] rounded-lg text-white font-WorkSans cursor-pointer'
              onClick={() => handleResendEmail()}
            >
              Resend Email
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Confirmation;