import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileModal from "./ProfileModal";
import ProfileCard from "./ProfileCard";
import ConfirmModal from "../../../global/ConfirmModal";
import {
  createProfile,
  getProfiles,
  removeProfile,
} from "../../../../actions/profile";

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [deleteModal, setShowDeleteModal] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  return (
    <>
      <div className="flex flex-col justify-start items-center space-y-4 w-full">
        <div className="grid grid-cols-3 -lg:grid-cols-2 -md:grid-cols-1 gap-8 w-full px-3 -sm:px-0">
          {profiles.length > 0 &&
            profiles.map((profile, index) => {
              return (
                <ProfileCard
                  key={index}
                  profile={profile?.profile}
                  onEdit={() =>
                    history.push(`/profile/${profile.profileId}/about`)
                  }
                  onView={() => history.push(`/share/${profile.profileId}`)}
                  onDelete={() => {
                    setShowDeleteModal(true);
                    setSelectedProfileId(profile?.profile.id);
                  }}
                />
              );
            })}
        </div>
        {profiles.length === 0 && (
          <div className="font-WorkSans text-center w-full">
            There is no profiles.
          </div>
        )}
        <div className="form-group">
          <button
            className="!w-[200px] !h-[50px] font-WorkSans !text-[16px]"
            onClick={() => setShowProfileModal(true)}
          >
            Create Profile
          </button>
        </div>
      </div>
      {showProfileModal && (
        <ProfileModal
          isLoading={isLoading}
          onClose={() => {
            setShowProfileModal(false);
          }}
          onSubmit={(values) => {
            setIsLoading(true);

            const formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("middleName", values.middleName);
            formData.append("lastName", values.lastName);
            formData.append("title", values.title);
            formData.append("relation", values.relation);
            formData.append("avatar", values.avatar);
            formData.append("isVeteran", values.isVeteran);
            formData.append("headline", values.headline);
            formData.append("obituaryLink", values.obituaryLink);
            formData.append("bio", values.bio);
            formData.append("birthDay", values.birthDay.toISOString());
            formData.append("deathDay", values.deathDay.toISOString());
            formData.append("city", values.city);
            formData.append("state", values.state);
            formData.append("quote", values.quote);

            dispatch(createProfile(formData))
              .then(() => {
                setIsLoading(false);
                setShowProfileModal(false);
                dispatch(getProfiles());
              })
              .catch(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {deleteModal && selectedProfileId && (
        <ConfirmModal
          title={"Delete profile"}
          description={"Do you want to remove this profile?"}
          confirmTitle={"Delete"}
          cancelTitle={"Cancel"}
          handleConfirm={() => {
            dispatch(removeProfile(selectedProfileId)).then(() => {
              setShowDeleteModal(false);
              setSelectedProfileId(null);
            });
          }}
          handleCancel={() => {
            setShowDeleteModal(false);
            setSelectedProfileId(null);
          }}
        />
      )}
    </>
  );
};

export default Profile;
