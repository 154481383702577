import React, { useEffect, useState } from "react";
import GroupModal from "./GroupModal";
import { useDispatch, useSelector } from "react-redux";
import { createGroup, getGroups, update } from "../../../../actions/group";
import GroupCard from "./group/GroupCard";

const Group = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const { groups } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  return (
    <>
      <div className="flex flex-col justify-start items-center space-y-4 w-full">
        <div className="grid grid-cols-3 -lg:grid-cols-2 -md:grid-cols-1 gap-8 w-full px-3 -sm:px-0">
          {groups.length > 0 &&
            groups.map((group, index) => {
              return (
                <GroupCard
                  key={index}
                  group={group}
                  onEdit={() => {
                    setSelectedGroup(group);
                    setShowGroupModal(true);
                  }}
                />
              );
            })}
        </div>
        {groups.length === 0 && (
          <div className="font-WorkSans text-center w-full">
            There is no group.
          </div>
        )}
        <div className="form-group">
          <button
            className="!w-[200px] !h-[50px] font-WorkSans !text-[16px]"
            onClick={() => setShowGroupModal(true)}
          >
            Create Group
          </button>
        </div>
      </div>
      {showGroupModal && (
        <GroupModal
          isLoading={isLoading}
          selectedGroup={selectedGroup}
          onClose={() => {
            setSelectedGroup(null);
            setShowGroupModal(false);
            setIsLoading(false);
          }}
          onSubmit={(values) => {
            setIsLoading(true);

            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("description", values.description);
            formData.append("avatar", values.avatar);
            formData.append("profileIds", JSON.stringify(values.profileIds));

            if (selectedGroup) {
              formData.append("groupId", selectedGroup.id);
              dispatch(update(formData))
                .then(() => {
                  dispatch(getGroups());
                  setSelectedGroup(null);
                  setIsLoading(false);
                  setShowGroupModal(false);
                })
                .catch(() => {
                  setSelectedGroup(null);
                  setIsLoading(false);
                });
            } else {
              dispatch(createGroup(formData))
                .then(() => {
                  dispatch(getGroups());
                  setIsLoading(false);
                  setShowGroupModal(false);
                })
                .catch(() => {
                  setIsLoading(false);
                });
            }
          }}
        />
      )}
    </>
  );
};

export default Group;
