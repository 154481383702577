import React from 'react';

const tabList = [
  { id: 1, title: "Profiles", route: '/profile' },
  { id: 2, title: "Groups", route: '/group' },
]

const TabNavigation = ({ active, onClick, children }) => {
  return (
    <div className='w-full h-full flex flex-col justify-start items-start bg-white -md:p-4 overflow-y-auto profile-container'>
      <div className='flex flex-row justify-start items-center space-x-8 -lg:space-x-6 w-full mb-8'>
        {tabList.map(tab => {
          return (
            <div
              key={tab.id} 
              className={`font-WorkSans cursor-pointer text-[16px] -sm:text-[12px] pb-1 px-4 font-bold border-b-[3px] ${tab.id === active ? 'border-b-[#10405A] text-[#10405A]' : 'text-[#8A9499] border-b-transparent'}`}
              onClick={() => onClick(tab.id, tab.route)}
            >
              {tab.title}
            </div>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default TabNavigation;