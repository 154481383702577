import api from './api';
import TokenService from './token.service';

const register = (firstname, lastname, email, password) => {
  return api.post("/auth/signup", {
    firstname,
    lastname,
    email,
    password,
  });
};

const login = (email, password) => {
  return api
    .post("/auth/signin", {
      email,
      password,
    })
};

const verify = (confirmationCode) => {
  return api.get(`/auth/verify/${confirmationCode}`);
}

const resend = (verify) => {
  return api.post("/auth/resend", {
    email: verify.email,
    firstname: verify.firstname
  })
}

const forgot = (email) => {
  return api.post("/auth/password/forgot", {
    email: email
  })
}

const reset = (token, password) => {
  return api.post("/auth/password/reset", {
    token: token,
    newPassword: password
  })
}

const logout = () => {
  TokenService.removeUser();
};

export default {
  register,
  login,
  verify,
  resend,
  forgot,
  reset,
  logout,
};
