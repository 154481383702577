import React from "react";
import { useHistory } from "react-router-dom";
import HeaderNav from "./landing/components/HeaderNav";

const MainLayout = ({ children }) => {
  const history = useHistory();

  return (
    <>
      <HeaderNav goSection={(secId) => {}} />
      <div className="flex flex-col justify-start items-center mx-auto min-w-[350px]">
        <div className="w-[100%] flex flex-row justify-center items-center pt-[100px] px-8 -sm:px-2">
          <div className="w-full max-w-[1300px] min-w-[250px] mx-auto mb-8">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
