import {
  USER_GET_DESIGNERS,
  USER_GET_DESIGNERS_SUCCESS,
  USER_GET_DESIGNERS_FAIL,

  // admin
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_CARDS_SUCCESS,
  GET_ALL_CARDS_FAIL,
} from "../actions/types";

const initialState = {
  users: {
    users: [],
    count: 0,
    hasMore: false
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: payload
      }
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        users: {
          users: [],
          count: 0,
          hasMore: false
        }
      }
    default:
      return state;
  }
}