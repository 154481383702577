import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login, resend } from "../../actions/auth";
import { required, validEmail } from "../../utils";
import { withRouter } from "react-router";
import { CgSpinner } from "react-icons/cg";
import { SET_MESSAGE } from "../../actions/types";

const SignIn = () => {
  const form = useRef(null);
  const checkBtn = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, verify } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then(() => {
          const intendedRoute = location.state?.from || '/dashboard/medallion/profile';
          history.push(intendedRoute);
        })
        .catch((errorMsg) => {
          if (errorMsg) {
            console.log(errorMsg);
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleResendEmail = () => {
    if (verify) {
      dispatch(resend(verify))
        .then((successMsg) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              success: true,
              message: successMsg,
            },
          });
        })
        .catch((errorMsg) => {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              success: false,
              message: errorMsg,
            },
          });
        });
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard/medallion/profile" />;
  }

  return (
    <div className="w-full">
      <span className="font-WorkSans text-[14px] select-none">
        Login with your email
      </span>
      <Form
        onSubmit={handleLogin}
        ref={form}
        className="flex flex-col justify-start itesm-center space-y-4 mt-[20px] w-full"
      >
        <div className="form-group w-full">
          <Input
            type="text"
            className="form-control !w-full"
            name="email"
            value={email}
            onChange={onChangeEmail}
            validations={[required, validEmail]}
            placeholder={"Email"}
          />
        </div>

        <div className="form-group w-full">
          <Input
            type="password"
            className="form-control !w-full"
            name="password"
            value={password}
            onChange={onChangePassword}
            validations={[required]}
            placeholder={"Password"}
          />
        </div>

        <span
          className="font-WorkSans text-[12px] select-none text-[#10405A] underline text-end w-[100%] cursor-pointer"
          onClick={() => history.push("/password_reset")}
        >
          I forgot my password
        </span>

        <div className="form-group flex flex-row justify-start items-center">
          <button disabled={loading}>
            {loading ? (
              <CgSpinner className={`text-white text-[30px] animate-spin`} />
            ) : (
              <span className="uppercase">Login</span>
            )}
          </button>
          {verify.email && (
            <span
              className="text-[#10405A] font-WorkSans underline cursor-pointer text-[14px] mt-[20px] ml-[20px]"
              onClick={() => handleResendEmail()}
            >
              Resend Email
            </span>
          )}
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
};

export default withRouter(SignIn);
