import {
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_GETALL_SUCCESS,
  GROUP_GETALL_FAIL,
  GROUP_GET_SUCCESS,
  GROUP_GET_FAIL,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL
} from "../actions/types";

const initialState = {
  groups: [],
  group: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GROUP_CREATE_SUCCESS:
      return {
        ...state,
      };
    case GROUP_CREATE_FAIL:
      return {
        ...state,
      };
    case GROUP_GET_SUCCESS:
      return {
        ...state,
        group: payload.group
      };
    case GROUP_GET_FAIL:
      return {
        ...state,
        group: {}
      };
    case GROUP_GETALL_SUCCESS:
      return {
        ...state,
        groups: payload.groups,
      };
    case GROUP_GETALL_FAIL:
      return {
        ...state,
        groups: [],
      };
    case GROUP_UPDATE_SUCCESS:
      return {
        ...state
      };
    case GROUP_UPDATE_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
}
