import React, { useState } from "react";
import moment from "moment";
import BlankAvatarSVG from "../../../../../assets/project/detail/blank.svg";
import { RiCloseCircleLine } from "react-icons/ri";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const RowMember = ({ profile, handleSelect }) => {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div
      className={`w-full flex flex-row justify-between items-center border rounded-xl px-4 py-2 ${isSelected && 'border border-[#10405A]'}`}
      style={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
    >
      <div className="flex flex-row justify-start items-center space-x-4">
        <img
          src={
            profile?.avatar
              ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.avatar}`
              : BlankAvatarSVG
          }
          className="w-[70px] -sm:w-[40px] h-[70px] -sm:h-[40px] rounded-full"
        />
        <div className="flex flex-col justify-start items-start">
          <p className="text-[18px] -sm:text-[14px] font-bold">{`${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}</p>
          <p className="text-[14px] -sm:text-[12px] text-gray-500">
            Created at: {moment(profile.createdAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center space-x-4">
        <div
          className={`flex flex-row justify-start items-center space-x-2 text-[#10405A] bg-gray-200 px-4 -sm:px-2 py-2 -sm:py-1 rounded-xl -sm:rounded-lg cursor-pointer`}
          onClick={() => {
            handleSelect(profile?.id)
            setIsSelected(!isSelected);
          }}
        >
          <span className="-sm:text-[12px]">{isSelected ? 'Remove' : 'Select'}</span>
        </div>
      </div>
    </div>
  );
};

export default RowMember;
