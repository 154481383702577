import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TabNavigation from "./medallion/TabNavigation";
import Profile from './medallion/Profile';
import Group from './medallion/Group';

const Medallions = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [active, setActive] = useState(1);

  useEffect(() => {
    if (location.pathname.includes('/profile')) {
      setActive(1);
    } else if (location.pathname.includes('/group')) {
      setActive(2);
    } else {
      console.log("here");
      history.push('/dashboard/medallion/profile');
    }
  }, [location])

  return (
    <div className='w-[100%] h-[100%] overflow-x-hidden'>
      <TabNavigation
        active={active}
        onClick={(id, route) => {
          setActive(id);
          history.push(`${path}${route}`);
        }}
        children={
          <Switch>
            <Route path={`${path}/profile`}>
              <Profile />
            </Route>
            <Route path={`${path}/group`}>
              <Group />
            </Route>
          </Switch>
        }
      />
    </div>
  );
};

export default Medallions;
