import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlankAvatarSVG from "../../assets/project/detail/blank.svg";
import { getShareProfile } from "../../actions/profile";
import MainLayout from "../MainLayout";
import ShareBanner from "./ShareBanner";
import { getTributes } from "../../actions/tribute";
import TributeModal from "./TributeModal";
import { FaPlus } from "react-icons/fa6";
import TributeCard from "./TributeCard";
import { getFavorite } from "../../actions/favorite";
import { SET_MESSAGE } from "../../actions/types";

const tabList = [
  { id: 1, title: "Bio" },
  { id: 2, title: "Photos" },
  { id: 3, title: "Videos" },
  { id: 4, title: "Tributes" },
  { id: 5, title: "Details" },
];
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const Share = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileId } = useParams();
  const { shareProfile } = useSelector((state) => state.profile);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isFavorited } = useSelector((state) => state.favorite);
  const { tributes } = useSelector((state) => state.tribute);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(1);
  const [showTributeModal, setShowTributeModal] = useState(false);

  useEffect(() => {
    if (profileId) {
      setIsLoading(true);
      const userId = currentUser ? currentUser?.id : null;
      dispatch(getShareProfile(profileId, userId))
        .then((res) => {
          setIsLoading(false);
          if (res && res.message) {
            history.push("/404");
          }
          if (
            res?.data?.profile &&
            res.data.profile.status === "Unlinked" &&
            !res.data.isOwner
          ) {
            dispatch({
              type: SET_MESSAGE,
              payload: {
                success: false,
                message: "Permission denied",
              },
            });
            history.push("/dashboard/medallion/profile");
          }
          if (currentUser) {
            dispatch(getTributes(profileId));
            dispatch(getFavorite({ profileId }));
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [profileId]);

  if (!shareProfile) {
    return null;
  }
  return (
    <MainLayout>
      <div className="w-[100%] h-[100%] overflow-x-hidden font-WorkSans">
        <ShareBanner
          profile={shareProfile?.profile}
          isFavorited={isFavorited}
        />
        <div className="w-full h-full flex flex-col justify-start items-start bg-white px-10 py-6 -md:p-4 overflow-y-auto profile-container mb-4">
          <div
            className="flex flex-row justify-center items-center space-x-12 -lg:space-x-6 w-full mb-12"
            style={{
              boxShadow: "inset 0 -2px 0 #edf1f2",
            }}
          >
            {tabList.map((tab) => {
              return (
                <div
                  key={tab.id}
                  className={`font-WorkSans cursor-pointer text-[16px] py-3 font-bold border-b-[3px] ${
                    tab.id === active
                      ? "border-b-[#10405A] text-[#10405A]"
                      : "text-[#8A9499] border-b-transparent"
                  }`}
                  onClick={() => setActive(tab.id)}
                >
                  {tab.title}
                </div>
              );
            })}
          </div>
          {active === 1 && (
            <div>
              <p>{shareProfile?.profile?.bio}</p>
            </div>
          )}
          {active === 2 && (
            <div className="grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 gap-8">
              {shareProfile?.profileImages.length > 0 &&
                shareProfile?.profileImages.map((profileImage, index) => {
                  return (
                    <div className="w-full" key={index}>
                      <img
                        src={
                          profileImage?.imageUrl
                            ? `${BACKEND_URL}/uploads/profiles/image/${profileImage.imageUrl}`
                            : BlankAvatarSVG
                        }
                        className="w-full rounded-t-lg"
                      />
                      <div className="flex flex-col justify-start items-start rounded-b-lg border p-4 text-[14px] bg-gray-100">
                        <p className="font-bold">Title:</p>
                        <span>{profileImage?.title}</span>
                        <p className="font-bold mt-2">Description:</p>
                        <span>{profileImage?.description}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {active === 3 && (
            <div className="grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 gap-8 w-full">
              {shareProfile?.profileVideos.length > 0 &&
                shareProfile?.profileVideos.map((profileVideo, index) => {
                  const videoId = profileVideo?.youtubeUrl.match(
                    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
                  );
                  return (
                    <div className="w-full" key={index}>
                      {videoId && (
                        <iframe
                          src={`https://www.youtube.com/embed/${videoId[1]}`}
                          allowFullScreen
                          title="video"
                          width={"100%"}
                          height={"auto"}
                          className="rounded-t-lg border"
                        />
                      )}
                      <div className="flex flex-col justify-start items-start rounded-b-lg border p-4 text-[14px] bg-gray-100">
                        <p className="font-bold">Title:</p>
                        <span>{profileVideo?.title}</span>
                        <p className="font-bold mt-2">Description:</p>
                        <span>{profileVideo?.description}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {active === 4 && (
            <div className="flex flex-col justify-start items-start w-full space-y-4">
              <div className="w-full flex flex-row justify-center items-center">
                {!currentUser ? (
                  <div
                    className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px]"
                    onClick={() => history.push("/login")}
                  >
                    <FaPlus />
                    <span>Sign in to see the posts</span>
                  </div>
                ) : (
                  <div
                    className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px]"
                    onClick={() => {
                      setShowTributeModal(true);
                    }}
                  >
                    <FaPlus />
                    <span>Create Tribute</span>
                  </div>
                )}
              </div>
              {currentUser && (
                <div className="flex flex-col justify-start items-start w-full">
                  {tributes.length > 0 &&
                    tributes.map((tribute, index) => {
                      return (
                        <TributeCard
                          key={index}
                          tribute={tribute}
                          user={tribute?.user}
                          images={tribute?.images}
                          profileId={profileId}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          )}
          {active === 5 && (
            <div className="flex flex-col justify-start items-start w-full space-y-4">
              <div className="flex flex-col justify-start items-start">
                <p className="font-bold text-[20px] !mb-2">
                  Cemetery information
                </p>
                <p className="text-gray-600 pl-2">
                  <span className="font-[600] text-[14px]">
                    Cemetery Name:{" "}
                  </span>
                  <span>{shareProfile?.profile?.cemeteryName}</span>
                </p>
                <p className="text-gray-600 pl-2">
                  <span className="font-[600] text-[14px]">
                    Cemetery Plot Number:{" "}
                  </span>
                  <span>{shareProfile?.profile?.cemeteryPlotNumber}</span>
                </p>
                <p className="text-gray-600 pl-2">
                  <span className="font-[600] text-[14px]">
                    Cemetery Location:{" "}
                  </span>
                  <span>
                    {shareProfile?.profile?.cemeteryCity}{" "}
                    {shareProfile?.profile?.cemeteryState}
                  </span>
                </p>
              </div>
              <div className="flex flex-col justify-start items-start w-full space-y-3">
                <p className="font-bold text-[20px] !mb-2">Links:</p>
                {shareProfile?.profileLinks.length > 0 &&
                  shareProfile.profileLinks.map((profileLink, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full flex flex-row justify-between items-center -md:items-start border rounded-lg px-8 py-4"
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <div className="grid grid-cols-2 -md:grid-cols-1 gap-4 flex-1">
                          <p className="flex flex-col justify-start items-start space-y-2 -md:space-y-0">
                            <span className="font-bold text-[14px]">
                              Title:{" "}
                            </span>
                            <span className="text-[16px]">
                              {profileLink.title}
                            </span>
                          </p>
                          <p className="flex flex-col justify-start items-start space-y-2 -md:space-y-0">
                            <span className="font-bold text-[14px]">URL: </span>
                            <a
                              className="text-[16px] underline text-blue-600"
                              href={profileLink.url}
                              target="_blank"
                            >
                              {profileLink.url}
                            </a>
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showTributeModal && profileId && (
        <TributeModal
          closeUploadModal={() => setShowTributeModal(false)}
          profileId={profileId}
        />
      )}
    </MainLayout>
  );
};

export default Share;
