import React from "react";
import moment from "moment";
import BlankAvatarSVG from "../../../../../assets/project/detail/blank.svg";
import { BsEyeFill, BsFillPencilFill } from "react-icons/bs";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const GroupCard = ({ group, onEdit }) => {
  if (!group) {
    return null;
  }
  const avatar = group?.avatar
    ? `${BACKEND_URL}/uploads/groups/avatar/${group.avatar}`
    : BlankAvatarSVG;
  return (
    <div
      className="w-full rounded-lg flex flex-col justify-start items-center py-8 px-4 space-y-1 text-center"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      <img
        src={avatar}
        className="rounded-full w-[200px] h-[200px] object-cover"
      />
      <p className="text-[20px] -sm:text-[18px] font-[600] font-WorkSans pt-2">{group?.name}</p>
      <p className="text-[14px] text-[#6c757d]">
        Created in {moment(group?.createdAt).format("MM/DD/YYYY")}
      </p>
      <div className="flex flex-row justify-center items-center space-x-1 !mt-4">
        {group?.profiles.length > 0 &&
          group.profiles.map((groupProfile, index) => {
            return (
              <img
                key={index}
                src={
                  groupProfile?.profile?.avatar
                    ? `${BACKEND_URL}/uploads/profiles/avatar/${groupProfile.profile.avatar}`
                    : BlankAvatarSVG
                }
                className="w-[35px] h-[35px] rounded-full"
              />
            );
          })}
      </div>
      <div className="w-full grid grid-cols-2 gap-4 !mt-4">
        <div
          className="flex flex-row justify-center items-center space-x-4 bg-[#e9eaea] py-2 rounded-lg cursor-pointer"
          onClick={() => {}}
        >
          <BsEyeFill />
          <span className="text-[16px] -sm:text-[14px]">View</span>
        </div>
        <div
          className="flex flex-row justify-center items-center space-x-4 bg-[#10405A] py-2 rounded-lg text-white cursor-pointer"
          onClick={onEdit}
        >
          <BsFillPencilFill />
          <span className="text-[16px] -sm:text-[14px]">Edit</span>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
