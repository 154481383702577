import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireUserLayout = ({children}) => {
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Redirect to={{ pathname: '/login', state: { from: history.location.pathname } }} />;
  }
  return (
    <>
      {children}
    </>
  )
}

export default RequireUserLayout;