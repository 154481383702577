import {
  TRIBUTE_CREATE_SUCCESS,
  TRIBUTE_CREATE_FAIL,
  TRIBUTE_GETALL_SUCCESS,
  TRIBUTE_GETALL_FAIL,
  TRIBUTE_UPDATE_SUCCESS,
  TRIBUTE_UPDATE_FAIL,
  TRIBUTE_REMOVE_SUCCESS,
  TRIBUTE_REMOVE_FAIL,
  TRIBUTE_GETALL_ADMIN_SUCCESS,
  TRIBUTE_GETALL_ADMIN_FAIL,
} from "../actions/types";

const initialState = {
  tributes: [],
  tributesAll: {
    tributes: [],
    count: 0,
    hasMore: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TRIBUTE_CREATE_SUCCESS:
      return {
        ...state,
      };
    case TRIBUTE_CREATE_FAIL:
      return {
        ...state,
      };
    case TRIBUTE_GETALL_SUCCESS:
      return {
        ...state,
        tributes: payload.tributes,
      };
    case TRIBUTE_GETALL_FAIL:
      return {
        ...state,
        tributes: [],
      };
    case TRIBUTE_GETALL_ADMIN_SUCCESS:
      return {
        ...state,
        tributesAll: payload,
      };
    case TRIBUTE_GETALL_ADMIN_FAIL:
      return {
        ...state,
        tributesAll: {
          tributes: [],
          count: 0,
          hasMore: false,
        },
      };
    case TRIBUTE_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case TRIBUTE_UPDATE_FAIL:
      return {
        ...state,
      };
    case TRIBUTE_REMOVE_SUCCESS:
      return {
        ...state,
      };
    case TRIBUTE_REMOVE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
