import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';
import EventBus from '../../common/EventBus';

const RequireAuthLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
      history.push('/login', { from: history.location.pathname });
    });
    return () => EventBus.remove("logout")
  }, [logOut]);

  return (
    <>
      {children}
    </>
  )
}

export default RequireAuthLayout;