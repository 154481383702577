import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import MainLayout from "./MainLayout";
import "./styles/Home.css";

const Home = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <MainLayout>
      <div className="relative max-w-[1920px] min-w-[380px] flex flex-col justify-center -sm:justify-start -sm:pt-20 items-center mx-auto overflow-x-hidden py-[100px]">
        <div className="w-full max-w-[1284px] h-[790px] -sm:h-auto flex flex-row justify-center items-start mx-auto px-4 -sm:px-0">
          <div className="auth-left w-[50%] -lg:hidden" />
          <div className="auth-right w-[50%] -lg:w-[540px] -sm:w-[90%] flex flex-col justify-center items-start">
            <p className="text-[38px] -sm:text-[30px] font-WorkSans select-none text-[#10405A] font-bold leading-[60px]">
              My Lasting Memories
            </p>
            <p className="text-[18px] -sm:text-[16px] text-[#494949] font-RNSSanzNormal select-none">
              Register for better way to remember
            </p>
            <div className="flex flex-col justify-center items-start w-full">
              <div className="flex flex-row justify-start items-center space-x-4 font-WorkSans my-[40px]">
                <p
                  className={`text-[13px] cursor-pointer uppercase border-b-2 ${
                    location?.pathname === '/login'
                      ? "border-[#10405A]"
                      : "border-[#EAEAEA] text-[#9E9E9E]"
                  }`}
                  onClick={() => {
                    history.push("/login")
                  }}
                >
                  Login
                </p>
                <p
                  className={`text-[13px] cursor-pointer uppercase border-b-2 ${
                    location?.pathname === '/login'
                      ? "border-[#EAEAEA] text-[#9E9E9E]"
                      : "border-[#10405A]"
                  }`}
                  onClick={() => {
                    history.push("/register")
                  }}
                >
                  Register
                </p>
              </div>
              <div className="flex flex-col justify-start items-center font-RNSSanzMedium w-full">
                {location?.pathname === '/login' ? (
                  <SignIn />
                ) : (
                  <SignUp />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
