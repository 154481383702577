import React from 'react';
import NotFoundSVG from '../assets/404-error.svg';

const NotFound = (props) => {
  return (
    <div className='overflow-x-hidden'>
      <div className='bg-white w-[100%] py-[100px] h-[100vh]'>
        <div className='max-w-[1300px] flex flex-col justify-center items-center mx-auto select-none'>
          <div className='relative flex flex-row justify-center items-center pt-28 -sm:pt-20 pb-24 -sm:pb-14 select-none'>
            <img src={NotFoundSVG} className="max-w-[600px] -md:max-w-[300px] -sm:max-w-[200px] z-10" />
            <span className='text-[350px] -md:text-[200px] -sm:text-[130px] font-WorkSans absolute m-auto text-[rgba(75,77,108,0.3)] tracking-[30px] -sm:tracking-[20px]'>
              404
            </span>
          </div>
          <div className='flex flex-col justify-center items-center font-WorkSans text-center space-y-8 max-w-[600px] -sm:max-w-[300px]'>
            <span className='text-[40px] -sm:text-[26px] text-[#3D474D] font-bold'>Page not found.</span>
            <span className='text-[16px] -sm:text-[14px] text-[#8a9499]'>
              {`The page you were trying to view doesn’t exist. You may have misstyped the address or the page may have been moved. Many apologies.`}
            </span>
            <div className='text-[18px] -sm:text-[16px] text-white bg-[rgb(75,77,108)] rounded-full shadow-lg px-10 py-4 cursor-pointer'
              onClick={() => props.history.push('/')}
            >
              Take me home
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;