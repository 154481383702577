import api from './api';

const favorite = (payload) => {
  return api.post("/profile/favorite", payload)
}

const getFavorite = (payload) => {
  return api.post("/profile/getFavorite", payload);
}

const getFavorites = () => {
  return api.get("/profile/getFavorites");
}

export default {
  favorite,
  getFavorite,
  getFavorites,
}