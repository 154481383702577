import React from 'react';

const tabList = [
  { id: 1, title: "About", route: '/about' },
  { id: 2, title: "Images", route: '/images' },
  { id: 3, title: "Videos", route: '/videos' },
  { id: 4, title: "Details", route: '/details' },
  { id: 5, title: "Admins", route: '/admins' }
]

const HorizontalNavigation = ({ active, onClick, children }) => {
  return (
    <div className='w-full h-full flex flex-col justify-start items-start bg-white px-10 py-6 -md:p-4 overflow-y-auto profile-container mb-4'>
      <div className='flex flex-row justify-center items-center space-x-12 -lg:space-x-6 w-full mb-12'
        style={{
          boxShadow: 'inset 0 -2px 0 #edf1f2'
        }}
      >
        {tabList.map(tab => {
          return (
            <div
              key={tab.id} 
              className={`font-WorkSans cursor-pointer text-[16px] py-3 font-bold border-b-[3px] ${tab.id === active ? 'border-b-[#10405A] text-[#10405A]' : 'text-[#8A9499] border-b-transparent'}`}
              onClick={() => onClick(tab.id, tab.route)}
            >
              {tab.title}
            </div>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default HorizontalNavigation;