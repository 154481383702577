import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FcAddImage } from "react-icons/fc";
import AttachedFileCard from "../profiles/components/AttachedFileCard";
import { MdOutlineClose } from "react-icons/md";
import { createTribute, getTributes, update } from "../../actions/tribute";
import { useDispatch } from "react-redux";

const TributeModal = ({ selectedTribute, closeUploadModal, profileId }) => {
  const dispatch = useDispatch();
  const [attachFiles, setAttachFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFile = (index) => {
    const attaches = [...attachFiles];
    attaches.splice(index, 1);
    setAttachFiles(attaches);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, "Name must be at least 2 characters long."),
    description: Yup.string().min(
      2,
      "Description must be at least 2 characters long."
    ),
  });

  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal font-WorkSans">
      <div className="relative mx-auto rounded-lg bg-white -md:w-[96%] md:w-[500px] my-[50px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">
            {selectedTribute ? "Edit tribute" : "Add new tribute"}
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={closeUploadModal}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-[100%] px-8">
          <div className="mt-2">
            <Dropzone
              accept="image/*"
              name="attachments"
              onDrop={(filesToUpload) => {
                let attaches = [...attachFiles];
                filesToUpload.forEach((file) => attaches.push(file));
                setAttachFiles(attaches);
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = isDragAccept ? 2 : isDragReject ? 0 : 1;

                return (
                  <div
                    {...getRootProps({
                      className: `flex flex-col justify-center my-4 py-8 cursor-pointer border border-gray-300 border-dashed rounded-lg bg-white ${
                        additionalClass === 2 && "border-red-600"
                      } ${additionalClass === 0 && "border-green-600"}`,
                    })}
                  >
                    <input {...getInputProps()} />
                    <span className="mx-auto text-7xl pb-2">
                      <FcAddImage />
                    </span>
                    <p className="text-lg text-gray-400 text-center">{`Drag & Drop Files Here`}</p>
                  </div>
                );
              }}
            </Dropzone>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-0 md:gap-1 px-8">
          {attachFiles.map((file, index) => (
            <AttachedFileCard
              key={index}
              onRemove={() => handleRemoveFile(index)}
              fileImage={URL.createObjectURL(file)}
              fileName={file.path}
              fileSize={file.size}
            />
          ))}
        </div>
        <div className="w-full flex flex-col justify-center items-center p-8">
          <Formik
            initialValues={{
              title: selectedTribute?.title || "",
              description: selectedTribute?.description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const formData = new FormData();
              formData.append("title", values.title);
              formData.append("description", values.description);
              formData.append("profileId", profileId);
              if (attachFiles.length > 0 && profileId) {
                for (let i = 0; i < attachFiles.length; i++) {
                  formData.append("files", attachFiles[i]);
                }
              } else {
                formData.append("files", []);
              }

              setIsLoading(true);
              if (selectedTribute) {
                formData.append("tributeId", selectedTribute.id)
                dispatch(update(formData))
                  .then(() => {
                    setIsLoading(false);
                    closeUploadModal();
                    dispatch(getTributes(profileId));
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              } else {
                dispatch(createTribute(formData))
                  .then(() => {
                    setIsLoading(false);
                    closeUploadModal();
                    dispatch(getTributes(profileId));
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              }
            }}
          >
            {() => (
              <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
                <div>
                  <label
                    htmlFor="name"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Title:
                  </label>
                  <Field
                    type="text"
                    id="title"
                    name="title"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Description:
                  </label>
                  <Field
                    component="textarea"
                    id="description"
                    name="description"
                    rows="3"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div className="w-full flex flex-row justify-center items-center space-x-2 my-4">
                  <button
                    type="submit"
                    className={`px-7 py-2 font-RNSSanzBold text-[15px] text-white bg-[#10405A] border-[#10405A] rounded-[10px] border cursor-pointer`}
                    disabled={isLoading}
                  >
                    Publish tribute
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TributeModal;
