import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_API_URL_DEVELOPMENT
      : process.env.REACT_APP_API_URL_DEVELOPMENT,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
