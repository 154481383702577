import React, { useState, useCallback } from "react";
import { MdOutlineClose } from "react-icons/md";
import Cropper from "react-easy-crop";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // To avoid CORS issues
    image.src = url;
  });

const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc); // Converts imageSrc to HTMLImageElement
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Set canvas dimensions to crop size
  canvas.width = crop.width;
  canvas.height = crop.height;

  // Draw the image onto the canvas, cropping it
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height
  );

  // Return the cropped image as a blob or data URL
  return canvas.toDataURL("image/jpeg");
};

const BannerUploadModal = ({
  title,
  closeModal,
  handleUpload,
  setFile,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 10 * 1000000) {
      // Max 10MB
      console.log("File size is too big");
      e.target.value = "";
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
      setFile(file); // Set the original file if needed
    };
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const base64CroppedImage = await getCroppedImg(imageSrc, croppedArea);
      handleUpload(base64CroppedImage); // Trigger upload (you may want to send base64 to server)
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedArea, handleUpload, setFile]);

  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-center justify-center z-20 overflow-y-scroll overflow-x-hidden`}
    >
      <div className="relative mx-auto rounded-[10px] bg-white md:w-[80%]">
        <div className="w-full min-h-[60px] px-6 mt-4 pb-2 rounded-tl-[20px] rounded-tr-[20px] flex justify-between items-center">
          <span className="font-WorkSans text-[20px]">{title}</span>
          <div
            className="flex justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={closeModal}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>

        <div className="w-full px-6 py-2 flex flex-col justify-center items-center">
          {imageSrc ? (
            <div className="relative w-full h-[390px]">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={16 / 5} // Custom aspect ratio for banners
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          ) : (
            <label className="w-full h-[390px] flex items-center justify-center cursor-pointer border-2 border-dashed border-gray-300">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              />
              Choose a banner image
            </label>
          )}

          <div className="w-full flex justify-end items-center space-x-2 mt-7 mb-5">
            <div
              className="px-7 py-1 font-WorkSans text-[15px] text-[#10405A] bg-[#eef0f2] border border-[#eef0f2] rounded-[5px] cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            {imageSrc && (
              <div
                className="px-7 py-1 font-WorkSans text-[15px] text-white bg-[#10405A] border border-[#10405A] rounded-[5px] cursor-pointer"
                onClick={showCroppedImage}
              >
                Save
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerUploadModal;
