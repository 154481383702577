// Auth types
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// User types
export const USER_GET_DESIGNERS = "USER_GET_DESIGNERS";
export const USER_GET_DESIGNERS_SUCCESS = "USER_GET_DESIGNERS_SUCCESS";
export const USER_GET_DESIGNERS_FAIL = "USER_GET_DESIGNERS_FAIL";
export const USER_SET_DESIGNER = "USER_SET_DESIGNER";
export const USER_SET_DESIGNER_SUCCESS = "USER_SET_DESIGNER_SUCCESS";
export const USER_SET_DESIGNER_FAIL = "USER_SET_DESIGNER_FAIL";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_NAME_SUCCESS = "USER_UPDATE_NAME_SUCCESS";
export const USER_UPDATE_NAME_FAIL = "USER_UPDATE_NAME_FAIL";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";

// Admin types
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS";
export const GET_ALL_CARDS_SUCCESS = "GET_ALL_CARDS_SUCCESS";
export const GET_ALL_CARDS_FAIL = "GET_ALL_CARDS_FAIL";


// Profile types
export const PROFILE_CREATE_SUCCESS = "PROFILE_CREATE_SUCCESS";
export const PROFILE_CREATE_FAIL = "PROFILE_CREATE_FAIL";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAIL = "PROFILE_GET_FAIL";
export const PROFILE_GETALL_SUCCESS = "PROFILE_GETALL_SUCCESS";
export const PROFILE_GETALL_FAIL = "PROFILE_GETALL_FAIL";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_IMAGE_FAIL = "PROFILE_IMAGE_FAIL";
export const PROFILE_VIDEO_SUCCESS = "PROFILE_VIDEO_SUCCESS";
export const PROFILE_VIDEO_FAIL = "PROFILE_VIDEO_FAIL";
export const PROFILE_LINK_SUCCESS = "PROFILE_LINK_SUCCESS";
export const PROFILE_LINK_FAIL = "PROFILE_LINK_FAIL";

export const SHARE_PROFILE_GET_SUCCESS = "SHARE_PROFILE_GET_SUCCESS";
export const SHARE_PROFILE_GET_FAIL = "SHARE_PROFILE_GET_FAIL";

// Group types
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";
export const GROUP_GETALL_SUCCESS = "GROUP_GETALL_SUCCESS";
export const GROUP_GETALL_FAIL = "GROUP_GETALL_FAIL";
export const GROUP_GET_SUCCESS = "GROUP_GET_SUCCESS";
export const GROUP_GET_FAIL = "GROUP_GET_FAIL";
export const GROUP_UPDATE_SUCCESS = "GROUP_UPDATE_SUCCESS";
export const GROUP_UPDATE_FAIL = "GROUP_UPDATE_FAIL";


// Tributes types
export const TRIBUTE_CREATE_SUCCESS = "TRIBUTE_CREATE_SUCCESS";
export const TRIBUTE_CREATE_FAIL = "TRIBUTE_CREATE_FAIL";
export const TRIBUTE_GETALL_SUCCESS = "TRIBUTE_GETALL_SUCCESS";
export const TRIBUTE_GETALL_FAIL = "TRIBUTE_GETALL_FAIL";
export const TRIBUTE_GETALL_ADMIN_SUCCESS = "TRIBUTE_GETALL_ADMIN_SUCCESS";
export const TRIBUTE_GETALL_ADMIN_FAIL = "TRIBUTE_GETALL_ADMIN_FAIL";
export const TRIBUTE_UPDATE_SUCCESS = "TRIBUTE_UPDATE_SUCCESS";
export const TRIBUTE_UPDATE_FAIL = "TRIBUTE_UPDATE_FAIL";
export const TRIBUTE_REMOVE_SUCCESS = "TRIBUTE_REMOVE_SUCCESS";
export const TRIBUTE_REMOVE_FAIL = "TRIBUTE_REMOVE_FAIL";


// Favorite types
export const FAVORITE_SUCCESS = "FAVORITE_SUCCESS";
export const FAVORITE_FAIL = "FAVORITE_FAIL";
export const FAVORITE_GET_SUCCESS = "FAVORITE_GET_SUCCESS";
export const FAVORITE_GET_FAIL = "FAVORITE_GET_FAIL";
export const FAVORITE_GETALL_SUCCESS = "FAVORITE_GETALL_SUCCESS";
export const FAVORITE_GETALL_FAIL = "FAVORITE_GETALL_FAIL";


// QR types
export const QR_CREATE_SUCCESS = "QR_CREATE_SUCCESS";
export const QR_CREATE_FAIL = "QR_CREATE_FAIL";
export const QR_GETALL_SUCCESS = "QR_GETALL_SUCCESS";
export const QR_GETALL_FAIL = "QR_GETALL_FAIL";
export const QR_GET_SUCCESS = "QR_GET_SUCCESS";
export const QR_GET_FAIL = "QR_GET_FAIL";