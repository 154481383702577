import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BlankAvatarSVG from "../../assets/project/detail/blank.svg";
import DropDownController from "./components/DropDownController";
import { CgSpinner } from "react-icons/cg";
import { FaMedal } from "react-icons/fa";
import { BsEyeFill } from "react-icons/bs";
import moment from "moment";
import BlankBannerIMG from "../../assets/dashboard/banner.png";
import AvatarUploadModal from "../dashboard/components/upload/AvatarUploadModal";
import BannerUploadModal from "../dashboard/components/upload/BannerUploadModal";
import { urltoFile } from "../../utils";
import { useDispatch } from "react-redux";
import {
  changeStatus,
  updateAvatar,
  get,
  updateBanner,
} from "../../actions/profile";
import StatusModal from "./components/StatusModal";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const Banner = ({ profile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [preview, setPreview] = useState(null);
  const [avatarFile, setFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showBannerUploadModal, setShowBannerUploadModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  return (
    <div className="w-full mb-[100px]">
      <div className="relative w-full">
        <img
          src={
            profile?.banner
              ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.banner}`
              : BlankBannerIMG
          }
          className="w-full rounded-t-lg aspect-[4/1.2] object-cover"
        />
        {isUploadingBanner && (
          <>
            <div className="absolute inset-0 w-full h-full bg-[#FFFFFF90] rounded-full" />
            <div className="absolute inset-0 w-full h-full m-auto flex flex-row justify-center items-center">
              <CgSpinner
                className={`text-[#10405A] text-[40px] animate-spin`}
              />
            </div>
          </>
        )}
        <div className="absolute right-[10px] top-[10px] -md:top-[10px] h-[24px] -md:h-[18px]">
          <DropDownController
            uploadAvatar={() => setShowBannerUploadModal(true)}
            removeAvatar={() => {}}
          />
        </div>
      </div>
      <div className="bg-white flex flex-row justify-start items-start px-12 -md:px-4">
        <div className="flex flex-col justify-center items-center space-y-4 transform translate-y-[-90px] -md:translate-y-[-50px]">
          <div className="relative w-[180px] -md:w-[100px] h-[180px] -md:h-[100px] rounded-full">
            <img
              src={
                profile?.avatar
                  ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.avatar}`
                  : BlankAvatarSVG
              }
              className="rounded-full w-full h-full object-cover border-[3px]"
            />
            {isUploading && (
              <>
                <div className="absolute inset-0 w-full h-full bg-[#FFFFFF90] rounded-full" />
                <div className="absolute inset-0 w-full h-full m-auto flex flex-row justify-center items-center">
                  <CgSpinner
                    className={`text-[#10405A] text-[40px] animate-spin`}
                  />
                </div>
              </>
            )}
            <div className="absolute right-[5px] bottom-[30px] -md:bottom-[10px] h-[24px] -md:h-[18px]">
              <DropDownController
                uploadAvatar={() => setShowUploadModal(true)}
                removeAvatar={() => {}}
              />
            </div>
          </div>
          {profile?.isVeteran && (
            <div className="flex flex-row justify-center items-center space-x-2 -sm:text-[12px]">
              <span>Veteran</span>
              <FaMedal />
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-row -md:flex-col justify-between -md:space-y-4 items-start p-8 font-WorkSans">
          <div className="flex flex-col justify-start items-start space-y-2">
            <p className="text-[15px] -sm:text-[13px]">
              <span className="font-bold">Name: </span>
              <span>{`${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}</span>
            </p>
            <p className="text-[15px] -sm:text-[13px] -sm:flex -sm:flex-col">
              <span className="font-bold">Lifetime: </span>
              <span>
                {`${moment(profile?.birthDay).format(
                  "MMM DD, YYYY"
                )} - ${moment(profile?.deathDay).format("MMM DD, YYYY")}`}
              </span>
            </p>
            <div
              className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl !mt-6 -md:!mt-2 cursor-pointer -sm:text-[13px]"
              onClick={() => {
                history.push(`/share/${profile.id}`);
              }}
            >
              <BsEyeFill />
              <span>Public Profile View</span>
            </div>
          </div>
          <div className="flex flex-col justify-start items-end space-y-2">
            <p className="text-[15px] font-bold -sm:text-[13px]">
              <span>Profile Status: </span>
              <span className="text-red-600 uppercase">{profile?.status}</span>
            </p>
            {profile?.status !== "Active" && (
              <p
                className="text-blue-500 underline text-right text-[14px] cursor-pointer"
                onClick={() => {
                  setShowStatusModal(true);
                }}
              >
                See more
              </p>
            )}
          </div>
        </div>
      </div>
      {showUploadModal && (
        <AvatarUploadModal
          closeModal={() => setShowUploadModal(false)}
          handleUpload={() => {
            if (avatarFile && preview) {
              setIsUploading(true);
              urltoFile(preview, avatarFile.name, avatarFile.type).then(
                (croppedFile) => {
                  const formData = new FormData();
                  formData.append("avatar", croppedFile);
                  formData.append("profileId", profile.id);

                  dispatch(updateAvatar(formData))
                    .then(() => {
                      setIsUploading(false);
                      setShowUploadModal(false);
                    })
                    .catch(() => {
                      setIsUploading(false);
                      setShowUploadModal(false);
                    });
                }
              );
            }
          }}
          preview={preview}
          setPreview={setPreview}
          setFile={setFile}
        />
      )}
      {showBannerUploadModal && (
        <BannerUploadModal
          closeModal={() => setShowBannerUploadModal(false)}
          handleUpload={(previewFile) => {
            if (bannerFile && previewFile) {
              setIsUploadingBanner(true);
              urltoFile(previewFile, bannerFile.name, bannerFile.type).then(
                (croppedFile) => {
                  const formData = new FormData();
                  formData.append("banner", croppedFile);
                  formData.append("profileId", profile.id);
                  dispatch(updateBanner(formData))
                    .then(() => {
                      setIsUploadingBanner(false);
                      setShowBannerUploadModal(false);
                    })
                    .catch(() => {
                      setIsUploadingBanner(false);
                      setShowBannerUploadModal(false);
                    });
                }
              );
            }
          }}
          setFile={setBannerFile}
        />
      )}
      {showStatusModal && (
        <StatusModal
          handleClose={() => {
            setShowStatusModal(false);
          }}
          status={profile?.status}
          handleChange={(status) => {
            if (profile?.id) {
              const payload = {
                status,
                profileId: profile.id,
              };
              dispatch(changeStatus(payload)).then(() => {
                setShowStatusModal(false);
                dispatch(get(profile.id));
              });
            }
          }}
        />
      )}
    </div>
  );
};

export default Banner;
