import {
  FAVORITE_SUCCESS,
  FAVORITE_FAIL,
  FAVORITE_GET_SUCCESS,
  FAVORITE_GET_FAIL,
  FAVORITE_GETALL_SUCCESS,
  FAVORITE_GETALL_FAIL,
} from "../actions/types";

const initialState = {
  favorites: [],
  isFavorited: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FAVORITE_SUCCESS:
      return {
        ...state,
      };
    case FAVORITE_FAIL:
      return {
        ...state,
      };
    case FAVORITE_GETALL_SUCCESS:
      return {
        ...state,
        favorites: payload.favorites,
      };
    case FAVORITE_GETALL_FAIL:
      return {
        ...state,
        favorites: [],
      };
    case FAVORITE_GET_SUCCESS:
      return {
        ...state,
        isFavorited: payload.favorited
      };
    case FAVORITE_GET_FAIL:
      return {
        ...state,
        isFavorited: false
      };
    default:
      return state;
  }
}
