import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../../assets/landing/logo.svg';
import Menu from '../../../assets/menu.png';
import HeaderDropDown from './HeaderDropDown';
import BlankAvatarSVG from '../../../assets/project/detail/blank.svg';
import { logout } from '../../../actions/auth';
import LOGO from '../../../assets/logo-white.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const HeaderNav = ({ goSection }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSidebar, setOpenSidebar] = useState(false);
  const { user: currentUser } = useSelector(state => state.auth);

  const menuLists = [
    { id: 1, title: 'Visit Store', secId: 1, link: "https://mylastingmemories.com" },
  ]

  return (
    <>
      {/* sidebar menu */}
      {openSidebar && <div className='fixed h-[100vh] inset-0 z-40 bg-black' onClick={() => setOpenSidebar(false)} />}
      <div className={`fixed z-40 top-0 bottom-0 right-0 overflow-x-hidden py-16 -sm:py-12 transition-all duration-[0.3s] -sm:transition-all -sm:duration-[0.5s]
      ${openSidebar ? `px-12 -sm:!w-[100%] -sm:px-8 -sm:!min-w-[100%]` : '!w-[0px] min-w-0'}`}
        style={{
          background: '#10405A',
          width: '100%'
        }}
      >
        <div
          className="absolute top-[5px] -sm:top-[13px] right-[13px] -sm:right-[24px] text-white text-[60px] -sm:text-[50px] text-bolder cursor-pointer"
          onClick={() => {
            setOpenSidebar(false);
          }}
        >
          &times;
        </div>
        <div className='flex flex-col justify-start items-start py-[30px] text-white font-WorkSans text-[25px]'>
          {menuLists.map((item, index) => {
            return (
              <div
                key={index}
                className="p-4 cursor-pointer"
                onClick={() => {
                  setOpenSidebar(false);
                  window.open(item.link, '_blank');
                }}
              >
                {item.title}
              </div>
            )
          })}
          {!currentUser &&
            <div className='p-4 cursor-pointer' onClick={() => {
              history.push("/login");
            }}>
              Login
            </div>
          }
        </div>
      </div>

      {/* header bar */}
      <div className={`fixed inset-x-0 top-0 py-[10px] px-[90px] -xl:px-10 -lg:px-4 -sm:px-6 ease-in-out duration-500 z-30`}
        style={{
          background: '#10405A',
        }}
      >
        <div className='max-w-[1250px] mx-auto flex flex-row justify-between items-center text-white font-WorkSans'>
          <div className='cursor-pointer' onClick={() => history.push("/")}>
            <img src={LOGO} className="inline h-[60px] -xl:h-[40px] cursor-pointer" onClick={() => goSection(1)} />
            {/* <span className='text-[40px] font-bold'>LOGO</span> */}
          </div>
          <div className=''>
            <div className='flex flex-row justify-end items-center lg:hidden space-x-2'>
              {currentUser &&
                <div className='flex flex-row justify-start items-center space-x-2'>
                  <img src={
                    currentUser && currentUser.avatar ?
                    `${BACKEND_URL}/uploads/users/avatar/${currentUser.avatar}` :
                      BlankAvatarSVG
                  } className='rounded-full w-[34px] h-[34px] border border-[#F39419]' />
                  <HeaderDropDown
                    logout={() => {
                      dispatch(logout());
                    }}
                  />
                </div>
              }
              <img
                src={Menu}
                className={`inline w-[30px] h-[30px] -sm:h-[25px] cursor-pointer`}
                onClick={() => {
                  setOpenSidebar(true);
                }}
              />
            </div>
            <div className='flex flex-row justify-center items-center space-x-8 -lg:space-x-4 text-[18px] -lg:hidden'>
              {menuLists.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='font-WorkSans cursor-pointer text-[16px]'
                    onClick={() => {
                      goSection(item.secId)
                      window.open(item.link, '_blank');
                    }}
                  >
                    {item.title}
                  </div>
                )
              })}
              {currentUser ?
                <div className='flex flex-row justify-start items-center space-x-2'>
                  <img src={
                    currentUser && currentUser.avatar ?
                    `${BACKEND_URL}/uploads/users/avatar/${currentUser.avatar}` :
                      BlankAvatarSVG
                  } className='rounded-full w-[34px] h-[34px] border border-[#F39419]' />
                  <HeaderDropDown
                    logout={() => {
                      dispatch(logout());
                    }}
                  />
                </div> :
                <div className='font-WorkSans cursor-pointer text-[16px]' onClick={() => {
                  history.push("/login");
                }}>
                  Login
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderNav;