import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AvatarUploadModal from "../upload/AvatarUploadModal";
import Headline from "./Headline";
import RowMember from "./group/MemberCard";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../../../actions/profile";
import { urltoFile } from "../../../../utils";

const GroupModal = ({ onClose, selectedGroup, isLoading, onSubmit }) => {
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.profile);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [preview, setPreview] = useState(null);
  const [avatarFile, setFile] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter the name.")
      .min(2, "Name must be at least 2 characters long."),
    description: Yup.string()
      .required("Please enter the description.")
      .min(2, "Description must be at least 2 characters long."),
  });

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  const handleMemberClick = (rowId) => {
    if (selectedMembers.includes(rowId)) {
      setSelectedMembers((prevSelectedMembers) =>
        prevSelectedMembers.filter((id) => id !== rowId)
      );
    } else {
      setSelectedMembers((prevSelectedMembers) => [
        ...prevSelectedMembers,
        rowId,
      ]);
    }
  };

  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal font-WorkSans">
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -lg:w-[90%] -sm:w-[96%] lg:w-[1100px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">
            {selectedGroup ? 'Edit group' : 'Create new group'}
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={onClose}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center p-8 -sm:p-6">
          <Formik
            initialValues={{
              name: selectedGroup?.name || "",
              description: selectedGroup?.description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (avatarFile && preview) {
                urltoFile(preview, avatarFile.name, avatarFile.type).then((croppedFile) => {
                  values.avatar = croppedFile;
                  values.profileIds = selectedMembers;
                  onSubmit(values, setSubmitting);
                })
              } else {
                values.profileIds = selectedMembers;
                onSubmit(values, setSubmitting);
              }
            }}
          >
            {() => (
              <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
                <Headline title="Group Details" />
                <div className="text-[12px] text-gray-500">
                  Start by entering as much info as you can about your group.
                  You will have a chance to update this later.
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Group name: *
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Group description: *
                  </label>
                  <Field
                    component="textarea"
                    id="description"
                    name="description"
                    rows="3"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="groupAvatar"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Group profile image:
                  </label>
                  <div className="flex flex-row items-center space-x-4">
                    <button
                      type="button"
                      className="max-w-x rounded-lg py-2 px-6 border border-[#eef0f2] bg-[#eef0f2] transition-colors text-[14px]"
                      onClick={() => setShowUploadModal(true)}
                    >
                      Upload Group Avatar
                    </button>
                    <span className="text-gray-600 text-sm block">
                      {avatarFile ? avatarFile.name : 'No file chosen'}
                    </span>
                  </div>
                </div>
                <Headline title="Members" />
                <div className="text-[12px] text-gray-500">
                  Select the members you wish to include on this group.
                </div>
                <div className="flex flex-col justify-start items-start space-y-4 w-full">
                  {profiles.length > 0 &&
                    profiles.map((profile, index) => {
                      return (
                        <RowMember
                          key={index}
                          profile={profile.profile}
                          handleSelect={(profileId) =>
                            handleMemberClick(profileId)
                          }
                        />
                      );
                    })}
                </div>
                <div className="flex w-full p-4 items-center justify-center font-WorkSans">
                  <button
                    type="submit"
                    className="w-full max-w-xs bg-[#10405A] text-white rounded-lg py-3 hover-bg-indigo-600 transition-colors text-[14px]"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showUploadModal && (
        <AvatarUploadModal
          title={"Crop Image"}
          closeModal={() => setShowUploadModal(false)}
          handleUpload={() => setShowUploadModal(false)}
          preview={preview}
          setPreview={setPreview}
          setFile={setFile}
        />
      )}
    </div>
  );
};

export default GroupModal;
