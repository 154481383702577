import api from './api';

const create = (payload) => {
  return api.post("/group/create", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const get = (groupId) => {
  return api.get("/group/get", {
    params: { groupId }
  });
}

const getGroups = () => {
  return api.get("/group/getGroups");
}

const update = (payload) => {
  return api.post("/group/update", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export default {
  create,
  get,
  getGroups,
  update
}