import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import MainLayout from "../MainLayout";
import HorizontalNavigation from "./components/HorizontalNavigation";
import MyAccount from "./components/MyAccount";
import Medallions from "./components/Medallions";
import MyFavorites from "./components/MyFavorites";
import DropDownController from "../profiles/components/DropDownController";
import MyPost from "./components/MyPost";
import AvatarUploadModal from "./components/upload/AvatarUploadModal";
import { urltoFile } from "../../utils";
import "../styles/DetailModal.css";
import { useDispatch, useSelector } from "react-redux";
import BlankAvatarSVG from "../../assets/project/detail/blank.svg";
import { CgSpinner } from "react-icons/cg";
import moment from "moment";
import { updateUserAvatar } from "../../actions/user";
// import HeartSVG from "../../assets/heart-new.svg";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const Dashboard = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const [active, setActive] = useState(4);
  const [preview, setPreview] = useState(null);
  const [avatarFile, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/favorite")) {
      setActive(1);
    } else if (location.pathname.includes("/post")) {
      setActive(2);
    } else if (location.pathname.includes("/medallion")) {
      setActive(3);
    } else if (location.pathname.includes("/account")) {
      setActive(4);
    } else {
      history.push("/dashboard/medallion/profile");
    }
  }, [location]);

  return (
    <MainLayout>
      <div className="w-[100%] h-[100%] overflow-x-hidden">
        <div className="flex flex-row justify-start items-center bg-white p-12 -sm:p-4 space-x-8 -sm:space-x-4">
          {/* <img src={HeartSVG} className="w-[180px] h-[180px]" /> */}
          <div className="relative w-[180px] -sm:w-[100px] h-[180px] -sm:h-[100px] rounded-full">
            <img
              src={
                currentUser?.avatar
                  ? `${BACKEND_URL}/uploads/users/avatar/${currentUser.avatar}`
                  : BlankAvatarSVG
              }
              className="rounded-full w-full h-full object-cover border-[3px]"
            />
            {isUploading && (
              <>
                <div className="absolute inset-0 w-full h-full bg-[#FFFFFF90] rounded-full" />
                <div className="absolute inset-0 w-full h-full m-auto flex flex-row justify-center items-center">
                  <CgSpinner
                    className={`text-[#10405A] text-[40px] -sm:text-[20px] animate-spin`}
                  />
                </div>
              </>
            )}
            <div className="absolute right-[5px] bottom-[30px] -sm:bottom-[10px] h-[24px] -sm:h-[18px]">
              <DropDownController
                uploadAvatar={() => setShowUploadModal(true)}
                removeAvatar={() => {}}
              />
            </div>
          </div>
          <div className="flex flex-col justify-start items-start space-y-2 font-WorkSans">
            <p className="text-[24px] -sm:text-[18px] font-bold">{currentUser?.firstname + " " + currentUser?.lastname}</p>
            <p className="text-[16px] -sm:text-[14px]">
              <span className="font-[600]">Email: </span>
              <span className="text-gray-500">{currentUser?.email}</span>
            </p>
            <p className="text-[16px] -sm:text-[14px]">
              <span className="font-[600]">Joined: </span>
              <span className="text-gray-500">
                {moment(currentUser?.createdAt).startOf("seconds").fromNow()}
              </span>
            </p>
          </div>
        </div>
        <HorizontalNavigation
          active={active}
          onClick={(id, route) => {
            setActive(id);
            history.push(`${path}${route}`);
          }}
          children={
            <Switch>
              <Route exact path={`${path}/favorite`}>
                <MyFavorites />
              </Route>
              <Route exact path={`${path}/post`}>
                <MyPost />
              </Route>
              <Route path={`${path}/medallion`}>
                <Medallions />
              </Route>
              <Route exact path={`${path}/account`}>
                <MyAccount />
              </Route>
            </Switch>
          }
        />
      </div>
      {showUploadModal && (
        <AvatarUploadModal
          closeModal={() => setShowUploadModal(false)}
          handleUpload={() => {
            if (avatarFile && preview) {
              setIsUploading(true);
              urltoFile(preview, avatarFile.name, avatarFile.type).then(
                (croppedFile) => {
                  const formData = new FormData();
                  formData.append("avatar", croppedFile);
                  dispatch(updateUserAvatar(formData))
                    .then(() => {
                      setShowUploadModal(false)
                      setIsUploading(false)
                      history.go(0);
                    })
                }
              );
            }
          }}
          preview={preview}
          setPreview={setPreview}
          setFile={setFile}
        />
      )}
    </MainLayout>
  );
};

export default Dashboard;
