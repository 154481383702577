import React, { useEffect, useState } from "react";
import HeartTemplateSVG from "../../assets/heart.svg";

const SvgHeartQrForPDF = ({ url }) => {
  return (
    <div className="w-[500px] h-[500px]">
      <img
        src={url}
        alt="JPEG"
        className="w-full h-full"
      />
    </div>
  );
};

// const SvgHeartQrForPDF = ({ url }) => {
//   return (
//     <div className="relative min-w-[550px] h-[500px]">
//       <img src={HeartTemplateSVG} alt="SVG 1" className="w-full h-full" />
//       <img
//         src={url}
//         alt="SVG 2"
//         className="absolute left-[172px] bottom-[117px] w-[210px] h-[210px]"
//         style={{ transform: "rotate(225deg)" }}
//       />
//     </div>
//   );
// };

export default SvgHeartQrForPDF;