import React, { useEffect, useState } from "react";
import HeartTemplateSVG from "../../assets/heart.svg";

const SvgHeartQr = ({ url }) => {
  return (
    <div className="relative w-[185px] h-[185px]">
      <img src={url} alt="JPEG" className="w-full h-full" />
    </div>
  );
};

// const SvgHeartQr = ({ url }) => {
//   return (
//     <div className="relative min-w-[376px] h-[300px]">
//       <img src={HeartTemplateSVG} alt="SVG 1" className="w-full h-full" />
//       <img
//         src={url}
//         alt="SVG 2"
//         className="absolute left-[126px] bottom-[69px] w-[125px] h-[125px]"
//         style={{ transform: "rotate(225deg)" }}
//       />
//     </div>
//   );
// };

export default SvgHeartQr;
