import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import UploadLinkModal from "./UploadLinkModal";
import { useDispatch, useSelector } from "react-redux";
import { getProfileLinks, removeProfileLink } from "../../../actions/profile";
import LinkRow from "./LinkRow";
import ConfirmModal from "../../global/ConfirmModal";

const ProfileLink = ({ profile }) => {
  const dispatch = useDispatch();
  const { profileLinks } = useSelector((state) => state.profile);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    if (profile) {
      dispatch(getProfileLinks(profile.id))
        .then(() => {})
        .catch(() => {});
    }
  }, [profile]);

  const handleRemove = (profileLink) => {
    setSelectedLink(profileLink);
    setShowRemoveModal(true);
  };

  const handleEdit = (profileLink) => {
    setSelectedLink(profileLink);
    setShowUploadModal(true);
  }

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center font-WorkSans space-y-4 mb-12">
        <div className="w-full flex flex-row justify-end items-center">
          <div
            className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px]"
            onClick={() => {
              setShowUploadModal(true);
            }}
          >
            <FaPlus />
            <span>Add Link</span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start w-full space-y-4">
          {profileLinks.length > 0 &&
            profileLinks.map((profileLink, index) => {
              return (
                <LinkRow
                  key={index}
                  title={profileLink.title}
                  url={profileLink.url}
                  handleEdit={() => handleEdit(profileLink)}
                  handleRemove={() => handleRemove(profileLink)}
                />
              );
            })}
        </div>
      </div>
      {showUploadModal && (
        <UploadLinkModal
          closeUploadModal={() => setShowUploadModal(false)}
          profile={profile}
          selectedLink={selectedLink}
        />
      )}
      {showRemoveModal && (
        <ConfirmModal
          title={"Remove link"}
          description={"Do you want to remove this link?"}
          confirmTitle={"Delete"}
          cancelTitle={"Cancel"}
          handleConfirm={() => {
            const payload = {
              profileId: profile.id,
              profileLinkId: selectedLink.id
            }
            dispatch(removeProfileLink(payload))
              .then(() => {
                setShowRemoveModal(false);
                setSelectedLink(null)
              })
              .catch(() => {
                setShowRemoveModal(false);
              })
          }}
          handleCancel={() => {
            setShowRemoveModal(false);
            setSelectedLink(null);
          }}
        />
      )}
    </>
  );
};

export default ProfileLink;
