import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import BlankAvatarSVG from "../../../assets/project/detail/blank.svg";
import { RiCloseCircleLine } from "react-icons/ri";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const RowAdmin = ({ admin, handleRemove, createdAt }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <div
      className="w-full flex flex-row justify-between items-center border rounded-xl px-4 py-2"
      style={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
    >
      <div className="flex flex-row justify-start items-center space-x-4">
        <img
          src={admin?.avatar && `${BACKEND_URL}/uploads/users/avatar/${admin.avatar}` || BlankAvatarSVG}
          className="w-[70px] -sm:w-[50px] h-[70px] -sm:h-[50px] rounded-full"
        />
        <div className="flex flex-col justify-start items-start">
          <p className="text-[18px] font-bold -sm:text-[15px]">{`${admin?.firstname} ${admin?.lastname}`}</p>
          <p className="text-[14px] text-gray-500 -sm:text-[12px]">
            Joined at: {moment(createdAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center space-x-4">
        {admin.id !== currentUser.id && (
          <div
            className="flex flex-row justify-start items-center space-x-2 text-[#10405A] bg-gray-200 px-4 py-2 rounded-xl cursor-pointer"
            onClick={handleRemove}
          >
            <RiCloseCircleLine />
            <span>Deactivate</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RowAdmin;
