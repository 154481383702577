import {
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_GETALL_SUCCESS,
  GROUP_GETALL_FAIL,
  GROUP_GET_SUCCESS,
  GROUP_GET_FAIL,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL,
  SET_MESSAGE
} from "./types";
import GroupService from "../services/group.service";

export const createGroup = (payload) => (dispatch) => {
  return GroupService.create(payload).then(
    (response) => {
      dispatch({ type: GROUP_CREATE_SUCCESS });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: GROUP_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const get = (groupId) => (dispatch) => {
  return GroupService.get(groupId).then(
    (response) => {
      dispatch({
        type: GROUP_GET_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: GROUP_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getGroups = () => (dispatch) => {
  return GroupService.getGroups().then(
    (response) => {
      dispatch({
        type: GROUP_GETALL_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: GROUP_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const update = (payload) => (dispatch) => {
  return GroupService.update(payload).then(
    (response) => {
      dispatch({
        type: GROUP_UPDATE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: GROUP_UPDATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};
