import {
  FAVORITE_SUCCESS,
  FAVORITE_FAIL,
  FAVORITE_GET_SUCCESS,
  FAVORITE_GET_FAIL,
  FAVORITE_GETALL_SUCCESS,
  FAVORITE_GETALL_FAIL,
  SET_MESSAGE,
} from "../actions/types";
import FavoriteService from "../services/favorite.service";

export const favorite = (payload) => (dispatch) => {
  return FavoriteService.favorite(payload).then(
    (response) => {
      dispatch({ type: FAVORITE_SUCCESS });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message
        }
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: FAVORITE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      return Promise.reject();
    }
  )
}

export const getFavorite = (payload) => (dispatch) => {
  return FavoriteService.getFavorite(payload).then(
    (response) => {
      dispatch({
        type: FAVORITE_GET_SUCCESS,
        payload: response.data
      })
      Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: FAVORITE_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      Promise.reject();
    }
  )
}

export const getFavorites = () => (dispatch) => {
  return FavoriteService.getFavorites().then(
    (response) => {
      dispatch({
        type: FAVORITE_GETALL_SUCCESS,
        payload: response.data
      })
      Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: FAVORITE_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message
        }
      });
      Promise.reject();
    }
  )
}