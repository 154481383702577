import api from './api';

const create = (payload) => {
  return api.post("/profile/create", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const get = (profileId) => {
  return api.get("/profile/get", {
    params: { profileId: profileId }
  });
}

const getProfiles = () => {
  return api.get("/profile/getProfiles");
}

const update = (payload) => {
  return api.post("/profile/update", payload);
}

const removeProfile = (profileId) => {
  return api.post("/profile/remove", {
    profileId
  })
}

const updateAvatar = (payload) => {
  return api.post("/profile/updateAvatar", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const updateBanner = (payload) => {
  return api.post("/profile/updateBanner", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

const createProfileImage = (payload) => {
  return api.post("/profileImages/create", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const getProfileImage = (profileId) => {
  return api.get("/profileImages/get", {
    params: { profileId: profileId}
  });
}

const updateProfileImage = (payload) => {
  return api.post("/profileImages/update", payload);
}

const removeProfileImage = (payload) => {
  return api.post("/profileImages/remove", payload);
}


const createProfileVideo = (payload) => {
  return api.post("/profileVideos/create", payload);
}

const getProfileVideo = (profileId) => {
  return api.get("/profileVideos/get", {
    params: { profileId: profileId}
  });
}

const removeProfileVideo = (payload) => {
  return api.post("/profileVideos/remove", payload);
}


const createProfileLink = (payload) => {
  return api.post("/profileLinks/create", payload);
}

const getProfileLink = (profileId) => {
  return api.get("/profileLinks/get", {
    params: { profileId: profileId}
  });
}

const removeProfileLink = (payload) => {
  return api.post("/profileLinks/remove", payload);
}

const updateProfileLink = (payload) => {
  return api.post("/profileLinks/update", payload);
}

const deactivateAdmin = (payload) => {
  return api.post("/profile/deactivate", payload);
}

const inviteAdmin = (payload) => {
  return api.post("/profile/inviteAdmin", payload)
}

const getShareProfile = (profileId, userId = null) => {
  return api.get("/profile/share/get", {
    params: { profileId, userId }
  })
}

const getTributes = () => {
  return api.get("/profile/getTributes");
}

const changeStatus = (payload) => {
  return api.post("/profile/changeStatus", payload);
}

export default {
  create,
  get,
  getProfiles,
  update,
  updateAvatar,
  updateBanner,
  createProfileImage,
  getProfileImage,
  updateProfileImage,
  removeProfileImage,
  createProfileVideo,
  getProfileVideo,
  removeProfileVideo,
  createProfileLink,
  updateProfileLink,
  getProfileLink,
  removeProfileLink,
  deactivateAdmin,
  inviteAdmin,
  getShareProfile,
  getTributes,
  removeProfile,
  changeStatus
}