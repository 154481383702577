import {
  QR_CREATE_SUCCESS,
  QR_CREATE_FAIL,
  QR_GETALL_SUCCESS,
  QR_GETALL_FAIL,
  QR_GET_SUCCESS,
  QR_GET_FAIL,
  SET_MESSAGE,
} from "../actions/types";
import QrService from "../services/qr.service";

export const createQR = (payload) => (dispatch) => {
  return QrService.create(payload).then(
    (response) => {
      dispatch({ type: QR_CREATE_SUCCESS });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: QR_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const get = (qrCodeId) => (dispatch) => {
  return QrService.get(qrCodeId).then(
    (response) => {
      dispatch({
        type: QR_GET_SUCCESS,
        payload: response.data.qr,
      });
      return Promise.resolve(response.data.qr);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: QR_GET_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const getQRCodes = (pageSize, pageNumber) => (dispatch) => {
  return QrService.getQRCodes(pageSize, pageNumber).then(
    (response) => {
      if (response.data.success) {
        dispatch({
          type: QR_GETALL_SUCCESS,
          payload: response.data.data,
        });
        return Promise.resolve();
      } else {
        dispatch({ type: QR_GETALL_FAIL });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: QR_GETALL_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const connectMedallion = (payload) => (dispatch) => {
  return QrService.connectMedallion(payload).then(
    (response) => {
      dispatch({
        type: QR_CREATE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: QR_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const createActiveProfile = (payload) => (dispatch) => {
  return QrService.createActiveProfile(payload).then(
    (response) => {
      dispatch({
        type: QR_CREATE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: QR_CREATE_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};

export const disconnect = (payload) => (dispatch) => {
  return QrService.disconnectMedallion(payload).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: true,
          message: response.data.message,
        },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: {
          success: false,
          message: message,
        },
      });
      return Promise.reject();
    }
  );
};
