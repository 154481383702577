import React, { useRef } from "react";
import { useHistory } from 'react-router-dom';
import HeaderNav from "./components/HeaderNav";
import LandingPNG from "../../assets/landing.png";

import "../styles/Landing.css";

const Landing = () => {
  const history = useHistory();
  return (
    <div>
      <HeaderNav goSection={(secId) => {}} />
      <div className="flex flex-row -lg:flex-col justify-center items-center h-[calc(100vh-80px)] min-h-[900px] font-WorkSans space-x-12 -lg:space-x-0 -lg:space-y-12 px-8 mt-[80px]">
        <div className="flex flex-col justify-start items-start max-w-[650px]">
          <p className="text-[30px] -lg:text-[24px] -sm:text-[16px] text-gray-600">Welcome to My Lasting Memories</p>
          <p className="text-[50px] font-bold leading-[50px] my-4 -lg:text-[40px] -sm:text-[30px]">
            <span className="text-black">A better way to</span>{" "}
            <span className="text-[#2166c2]">remember</span>
          </p>
          <p className="text-[24px] -lg:text-[20px] -sm:text-[14px]">
            To start setting up the medallion, create an account or sign in
            below.
          </p>
          <div className="grid grid-cols-2 gap-8 w-full mt-12">
            <div
              className="w-full flex flex-row justify-center items-center space-x-2 text-[#10405A] font-bold bg-transparent border-[2px] border-[#10405A] py-2 rounded-xl cursor-pointer text-[20px] -sm:text-[13px]"
              onClick={() => {
                history.push("/register");
              }}
            >
              <span>Register</span>
            </div>
            <div
              className="w-full flex flex-row justify-center items-center space-x-2 text-white font-bold bg-[#10405A] border-[2px] border-[#10405A] py-2 rounded-xl cursor-pointer text-[20px] -sm:text-[13px]"
              onClick={() => {
                history.push("/login");
              }}
            >
              <span>Login</span>
            </div>
          </div>
        </div>
        <img src={LandingPNG} className="rounded-[16px] object-cover w-[47%] -lg:w-[100%] max-w-[700px]" />
      </div>
    </div>
  );
};

export default Landing;
