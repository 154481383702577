import React from 'react';

const tabList = [
  { id: 1, title: "My favorites", route: '/favorite' },
  { id: 2, title: "Posts", route: '/post' },
  { id: 3, title: "Medallions", route: '/medallion/profile' },
  { id: 4, title: "My account", route: '/account' }
]

const HorizontalNavigation = ({ active, onClick, children }) => {
  return (
    <div className='w-full h-full flex flex-col justify-start items-start bg-white p-10 -md:p-4 overflow-y-auto profile-container'>
      <div className='flex flex-row justify-center items-center space-x-12 -lg:space-x-6 w-full mb-12 -sm:mb-4'
        style={{
          boxShadow: 'inset 0 -2px 0 #edf1f2'
        }}
      >
        {tabList.map(tab => {
          return (
            <div
              key={tab.id} 
              className={`font-WorkSans cursor-pointer text-[16px] -sm:text-[12px] py-3 font-bold border-b-[3px] ${tab.id === active ? 'border-b-[#10405A] text-[#10405A]' : 'text-[#8A9499] border-b-transparent'}`}
              onClick={() => onClick(tab.id, tab.route)}
            >
              {tab.title}
            </div>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default HorizontalNavigation;