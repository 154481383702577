import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { register } from "../../actions/auth";
import { CgSpinner } from "react-icons/cg";
import {
  required,
  vusername,
  validEmail,
  vpassword,
  vpasswordConfirm,
} from "../../utils";

const SignUp = () => {
  const form = useRef();
  const checkBtn = useRef();
  const history = useHistory();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onConfirmPassword = (e) => {
    const password = e.target.value;
    setPasswordConfirm(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(firstname, lastname, email, password))
        .then(() => {
          setIsLoading(false);
          history.push("/confirmation");
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <span className="font-WorkSans text-[14px] select-none">
        Register with your email
      </span>
      <Form
        onSubmit={handleRegister}
        ref={form}
        className="flex flex-col justify-start itesm-center space-y-4 mt-[20px] w-full"
      >
        <>
          <div className="flex flex-row -sm:flex-col -sm:space-y-4 justify-start items-start w-full space-x-4 -sm:space-x-0">
            <div className="form-group w-full">
              <Input
                type="text"
                className="form-control !w-full"
                name="firstname"
                value={firstname}
                onChange={onChangeFirstname}
                validations={[required, vusername]}
                placeholder={"First Name"}
              />
            </div>
            <div className="form-group w-full">
              <Input
                type="text"
                className="form-control !w-full"
                name="lastname"
                value={lastname}
                onChange={onChangeLastname}
                validations={[required, vusername]}
                placeholder={"Last Name"}
              />
            </div>
          </div>
          <div className="form-group w-full">
            <Input
              type="text"
              className="form-control !w-full"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[required, validEmail]}
              placeholder={"Email"}
            />
          </div>
          <div className="form-group w-full">
            <Input
              type="password"
              className="form-control !w-full"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required, vpassword]}
              placeholder={"Password"}
            />
          </div>
          <div className="form-group w-full">
            <Input
              type="password"
              className="form-control !w-full"
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={onConfirmPassword}
              validations={[required, vpasswordConfirm]}
              placeholder={"Confirm Password"}
            />
          </div>
          <div className="form-group">
            <button disabled={isLoading}>
              {isLoading ? (
                <CgSpinner className={`text-white text-[30px] animate-spin`} />
              ) : (
                <span className="uppercase">Register</span>
              )}
            </button>
          </div>
        </>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  );
};

export default SignUp;
