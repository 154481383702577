import React, { useState, useMemo, useEffect } from "react";
import MainLayout from "../MainLayout";
import { CgSpinner } from "react-icons/cg";
import ConfirmModal from "../global/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllForAdmin, approve } from "../../actions/tribute";
import DataTable from "react-data-table-component";

const CustomLoader = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center space-y-8 mt-16">
      <CgSpinner className={`text-[#10405A] text-[70px] animate-spin`} />
      <span className="text-[16px] font-SenSans text-[#10405A]">
        Loading the data...
      </span>
    </div>
  );
};

const TributeManagement = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const { tributesAll: result } = useSelector((state) => state.tribute);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedTribute, setSelectedTribute] = useState(null);

  const columns = useMemo(() => [
    {
      selector: (row, index) => index + 1 + (curPage - 1) * 10,
      width: "50px",
    },
    {
      name: "Title",
      columnName: "title",
      selector: (row) => row.title,
    },
    {
      name: "Description",
      columnName: "description",
      selector: (row) => row.description,
      minWidth: "400px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="text-[12px]">
          {row.approve ? (
            <p className="px-4 py-2 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Approved
            </p>
          ) : (
            <p className="px-4 py-2 text-[#f06548] rounded-sm bg-[rgba(240,101,72,.1)] font-bold">
              Pending
            </p>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-end items-center space-x-2 text-[16px] cursor-pointer font-WorkSans">
          {!row.approve && (
            <div
              className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-lg cursor-pointer text-[13px]"
              onClick={() => {
                setShowApproveModal(true);
                setSelectedTribute(row);
              }}
            >
              <span>Approve</span>
            </div>
          )}
        </div>
      ),
      right: true,
    },
  ]);

  useEffect(() => {
    fetchTributes();
  }, [curPage]);

  const fetchTributes = () => {
    setIsLoading(true);
    dispatch(getAllForAdmin(20, curPage))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurPage(page);
  };

  return (
    <MainLayout>
      <div className="w-[100%] h-[100%] overflow-x-hidden font-WorkSans">
        <div className="w-full pt-[50px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 overflow-y-auto">
          <div className="w-full flex flex-row -lg:flex-col -lg:space-y-2 justify-between items-center mb-4">
            <span className="text-[24px] font-bold text-[#3D474D] mb-2 -lg:w-full">
              Tributes Management
            </span>
          </div>
          <div className="w-[100%] data-table rounded-lg my-8">
            <DataTable
              columns={columns}
              data={result.tributes}
              pagination
              paginationServer
              paginationTotalRows={result.count}
              progressPending={isLoading}
              progressComponent={<CustomLoader />}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              noDataComponent={"There is no tributes"}
            />
          </div>
        </div>
      </div>
      {showApproveModal && selectedTribute && (
        <ConfirmModal
          title={"Approve Tribute"}
          description={"Do you want to approve this tribute?"}
          confirmTitle={"Approve"}
          cancelTitle={"Cancel"}
          handleCancel={() => {
            setShowApproveModal(false);
            setSelectedTribute(null);
          }}
          handleConfirm={() => {
            const payload = {
              tributeId: selectedTribute.id,
            };
            dispatch(approve(payload)).then(() => {
              setShowApproveModal(false);
              fetchTributes();
            });
          }}
        />
      )}
    </MainLayout>
  );
};

export default TributeManagement;
