import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from 'react-icons/cg';
import { required, vusername } from '../../../utils';
import { updateUsername } from '../../../actions/user';

const General = () => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setFirstname(currentUser.firstname);
      setLastname(currentUser.lastname);
    }
  }, [currentUser])

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  }

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(updateUsername({ firstname: firstname, lastname: lastname }))
        .then(() => {
          setIsLoading(false);
        })
    } else {
      setIsLoading(false);
    }
  }

  return (
    <div className='flex flex-col justify-start items-start space-y-4'>
      <span className='text-[21px] font-WorkSans text-[#636363] font-bold'>
        Edit Profile
      </span>
      <div className='flex flex-row -sm:flex-col justify-start items-start space-x-16 -sm:space-x-0 -sm:space-y-8'>
        <Form onSubmit={handleUpdate} ref={form} className="flex flex-col justify-start itesm-center space-y-4">
          <div className='flex flex-row -sm:flex-col justify-start items-center space-x-4 -sm:space-x-0 -sm:space-y-4'>
            <div className='form-group'>
              <label htmlFor='firstname' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>First Name</label>
              <Input
                type="text"
                className="form-control !w-[250px] !bg-white text-[14px]"
                name="firstname"
                value={firstname}
                onChange={onChangeFirstname}
                validations={[required, vusername]}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='lastname' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>Last Name</label>
              <Input
                type="text"
                className="form-control !w-[250px] !bg-white text-[14px]"
                name="lastname"
                value={lastname}
                onChange={onChangeLastname}
                validations={[required, vusername]}
              />
            </div>
          </div>
          <div className='form-group'>
            <button className='!w-[150px] !h-[40px] font-WorkSans !text-[14px]'>
              {isLoading ?
                <CgSpinner className={`text-white text-[30px] animate-spin`} /> :
                <span>Save changes</span>
              }
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  )
}

export default General;