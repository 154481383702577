import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { BsFillPencilFill } from 'react-icons/bs';

export default function DropDownController({
  uploadAvatar,
  removeAvatar,
  // currentUser
}) {
  return (
    <div className="text-right">
      <Menu as="div" className="relative text-left">
        <Menu.Button className="flex w-full justify-center items-center bg-transparent">
          <div className='w-[35px] -sm:w-[30px] h-[35px] -sm:h-[30px] rounded-full border-[2px] bg-[#10405A] flex flex-row justify-center items-center cursor-pointer'>
            <BsFillPencilFill
              className='text-[13px] -sm:text-[12px] text-white'
              aria-hidden="true"
            />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-[0px] -sm:right-[-50px] mt-[15px] w-[170px] -sm:w-[130px] origin-top-right z-1000 rounded-[10px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
            <div className="px-2 font-RNSSanzMedium">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-[rgba(230,247,255,1)]' : ''
                      } group flex w-full items-center justify-start rounded-md px-2 py-2 text-[#3d474d] cursor-pointer space-x-2`}
                    onClick={() => uploadAvatar()}
                  >
                    <span className='text-[16px] -sm:text-[12px] font-JosefinSans'>Upload Photo</span>
                  </div>
                )}
              </Menu.Item>
            </div>
            {/* <div className="px-2 font-RNSSanzMedium">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-[rgba(230,247,255,1)]' : ''
                      } group flex w-full items-center justify-start rounded-md px-2 py-2 text-[#3d474d] cursor-pointer space-x-2`}
                    onClick={() => {
                      // if(currentUser && currentUser.avatar) {
                        removeAvatar()
                      // }
                    }}
                  >
                    <span className={`text-[16px] font-JosefinSans`}>Remove</span>
                  </div>
                )}
              </Menu.Item>
            </div> */}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}