import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { relations } from "../../../utils";
import DatePickerField from "../../dashboard/components/medallion/DatePicker";
import Headline from "../../dashboard/components/medallion/Headline";
import { update } from "../../../actions/profile";
import { useDispatch } from "react-redux";

const About = ({ profile }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter the first name.")
      .min(2, "First name must be at least 2 characters long."),
    middleName: Yup.string().min(
      2,
      "Middle name must be at least 2 characters long."
    ),
    lastName: Yup.string()
      .required("Please enter the last name.")
      .min(2, "Last name must be at least 2 characters long."),
    title: Yup.string().min(2, "Title must be at least 2 characters long."),
    bio: Yup.string().min(
      10,
      "The obituary text must be at least 10 characters long."
    ),
    birthDay: Yup.string().required("Please select the birth date."),
    deathDay: Yup.string().required("Please select the death date."),
    city: Yup.string().min(2, "The city must be at least 2 characters long."),
    state: Yup.string().min(2, "The state must be at least 2 characters long."),
    cemeteryName: Yup.string().min(
      2,
      "The cemetery name must be at least 2 characters long."
    ),
    cemeteryPlotNumber: Yup.string().min(
      2,
      "The cemetery plot number must be at least 2 characters long."
    ),
    cemeteryCity: Yup.string().min(
      2,
      "The cemetery city must be at least 2 characters long."
    ),
    cemeteryState: Yup.string().min(
      2,
      "The cemetery state must be at least 2 characters long."
    ),
    cemeteryLocation: Yup.string().min(
      2,
      "The cemetery state must be at least 2 characters long."
    ),
    donationUrl: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a valid URL."
    ),
  });

  return (
    <div className="w-full flex flex-col justify-center items-center font-WorkSans">
      <Formik
        initialValues={{
          firstName: profile?.firstName || "",
          middleName: profile?.middleName || "",
          lastName: profile?.lastName || "",
          title: profile?.title || "",
          relation: profile?.relation || "",
          isVeteran: profile?.isVeteran || false,
          headline: profile?.headline || "",
          isIncludeHeadline: false,
          obituaryLink: profile?.obituaryLink || "",
          bio: profile?.bio || "",
          birthDay: profile?.birthDay || null,
          deathDay: profile?.deathDay || null,
          city: profile?.city || "",
          state: profile?.state || "",
          quote: profile?.quote || "",
          cemeteryName: profile?.cemeteryName || "",
          cemeteryPlotNumber: profile?.cemeteryPlotNumber || "",
          cemeteryCity: profile?.cemeteryCity || "",
          cemeteryState: profile?.cemeteryState || "",
          cemeteryLocation: profile?.cemeteryLocation || "",
          donationUrl: profile?.donationUrl || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true)
          if (values.isIncludeHeadline) {
            values.headline = "";
          }
          values.profileId = profile?.id;
          console.log("values: ", values);

          dispatch(update(values))
            .then(() => {
              setIsLoading(false)
            })
            .catch(() => {
              setIsLoading(false)
            })
        }}
      >
        {() => (
          <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
            <Headline title="Personal Details" />
            <div className="grid grid-cols-3 -sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="text-gray-600 text-sm block mb-1"
                >
                  First name: *
                </label>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="middleName"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Middle name:
                </label>
                <Field
                  type="text"
                  id="middleName"
                  name="middleName"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="middleName"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Last name: *
                </label>
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="title"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Title:
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Example Jr - Sr"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="relation"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Relationship:
                </label>
                <Field
                  as="select"
                  id="relation"
                  name="relation"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                >
                  <option value="">-- Select an option --</option>
                  {relations.map((relation, index) => {
                    return (
                      <option value={relation} key={index}>
                        {relation}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="pt-5">
              <label className="flex flex-row items-center space-x-2">
                <Field type="checkbox" id="isVeteran" name="isVeteran" />
                <span className="text-gray-600 text-sm block cursor-pointer">
                  Is a Veteran?
                </span>
              </label>
            </div>
            <Headline title="Headline text" />
            <div>
              <label
                htmlFor="headline"
                className="text-gray-600 text-sm block mb-1"
              >
                Text or phrase:
              </label>
              <Field
                type="text"
                id="headline"
                name="headline"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
              />
              <div className="text-[12px] mt-2 text-gray-500">
                This headline text is the one that shows above the name of the
                person. If this field is null, the headline text won’t be added.
              </div>
              <label className="flex flex-row items-center space-x-2 pt-4">
                <Field
                  type="checkbox"
                  id="isIncludeHeadline"
                  name="isIncludeHeadline"
                />
                <span className="text-gray-600 text-sm block cursor-pointer">
                  Don't include headline text
                </span>
              </label>
            </div>
            <Headline title="Obituary Information" />
            <div>
              <label
                htmlFor="obituaryLink"
                className="text-gray-600 text-sm block mb-1"
              >
                Link to Obituary:
              </label>
              <Field
                type="text"
                id="obituaryLink"
                name="obituaryLink"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
              />
              <ErrorMessage
                name="obituaryLink"
                component="div"
                className="text-red-500 !text-[12px]"
              />
            </div>
            <div>
              <label htmlFor="bio" className="text-gray-600 text-sm block mb-1">
                Bio information:
              </label>
              <Field
                component="textarea"
                id="bio"
                name="bio"
                rows="3"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
              />
              <ErrorMessage
                name="bio"
                component="div"
                className="text-red-500 !text-[12px]"
              />
            </div>
            <Headline title="Lifetime" />
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div className="w-full">
                <label
                  htmlFor="birthDay"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Birth date: *
                </label>
                <DatePickerField
                  name="birthDay"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="birthDay"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="deathDay"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Death date: *
                </label>
                <DatePickerField
                  name="deathDay"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="deathDay"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
            </div>
            <Headline title="Location before death" />
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="city"
                  className="text-gray-600 text-sm block mb-1"
                >
                  City:
                </label>
                <Field
                  type="text"
                  id="city"
                  name="city"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="state"
                  className="text-gray-600 text-sm block mb-1"
                >
                  State:
                </label>
                <Field
                  type="text"
                  id="state"
                  name="state"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
            </div>
            <Headline title="Cemetery information" />
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="cemeteryName"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Cemetery name:
                </label>
                <Field
                  type="text"
                  id="cemeteryName"
                  name="cemeteryName"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="cemeteryName"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="cemeteryPlotNumber"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Cemetery plot number:
                </label>
                <Field
                  type="text"
                  id="cemeteryPlotNumber"
                  name="cemeteryPlotNumber"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="cemeteryPlotNumber"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="cemeteryCity"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Cemetery city:
                </label>
                <Field
                  type="text"
                  id="cemeteryCity"
                  name="cemeteryCity"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="cemeteryCity"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
              <div>
                <label
                  htmlFor="cemeteryState"
                  className="text-gray-600 text-sm block mb-1"
                >
                  Cemetery state:
                </label>
                <Field
                  type="text"
                  id="cemeteryState"
                  name="cemeteryState"
                  className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                />
                <ErrorMessage
                  name="cemeteryState"
                  component="div"
                  className="text-red-500 !text-[12px]"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="cemeteryLocation"
                className="text-gray-600 text-sm block mb-1"
              >
                Cemetery plot location:
              </label>
              <Field
                type="text"
                id="cemeteryLocation"
                name="cemeteryLocation"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
              />
              <ErrorMessage
                name="cemeteryLocation"
                component="div"
                className="text-red-500 !text-[12px]"
              />
            </div>
            <Headline title="Donations" />
            <div>
              <label
                htmlFor="donationUrl"
                className="text-gray-600 text-sm block mb-1"
              >
                Donation URL:
              </label>
              <Field
                type="text"
                id="donationUrl"
                name="donationUrl"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
              />
              <ErrorMessage
                name="donationUrl"
                component="div"
                className="text-red-500 !text-[12px]"
              />
            </div>
            <Headline title="Quote Section" />
            <div>
              <label
                htmlFor="quote"
                className="text-gray-600 text-sm block mb-1"
              >
                Text or phrase:
              </label>
              <Field
                component="textarea"
                id="quote"
                name="quote"
                rows="3"
                className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                placeholder={`"Never let the fear of striking out keep you from playing the game." - Babe Ruth`}
              />
              <div className="text-[12px] mt-1 text-gray-500">
                This headline text is the one that shows above the name of the
                person.
              </div>
            </div>

            <div className="flex w-full p-4 items-center justify-center font-WorkSans">
              <button
                type="submit"
                className="w-full max-w-xs bg-[#10405A] text-white rounded-lg py-3 hover-bg-indigo-600 transition-colors text-[14px]"
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Save changes"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default About;
