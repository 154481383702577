import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from 'react-icons/cg';
import { required, vpassword, vpasswordConfirm } from '../../../utils';
import { updatePassword } from '../../../actions/user';

const ProfilePassword = () => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const onChangeCurrentPassword = (e) => {
    const currentPassword = e.target.value;
    setCurrentPassword(currentPassword);
  }

  const onChangeNewPassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
  }

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  }

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(updatePassword({
        currentPassword: currentPassword,
        newPassword: newPassword
      }))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        })
    } else {
      setIsLoading(false);
    }
  }
  return (
    <div className='flex flex-col justify-start items-start space-y-4 w-full'>
      <span className='text-[21px] font-WorkSans text-[#636363] font-bold'>
        Change Password
      </span>
      <Form onSubmit={handleUpdatePassword} ref={form} className="flex flex-col justify-start itesm-center space-y-4 w-full">
        <div className='flex flex-col justify-start items-start space-y-2 w-full'>
          <div className='form-group w-full'>
            <label htmlFor='currentPassword' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>
              Current Password
            </label>
            <Input
              type="password"
              className="form-control !w-full !bg-white text-[14px]"
              name="currentPassword"
              value={currentPassword}
              onChange={onChangeCurrentPassword}
              validations={[required, vpassword]}
            />
          </div>
          <div className='form-group w-full'>
            <label htmlFor='password' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>New Password</label>
            <Input
              type="password"
              className="form-control !w-full !bg-white text-[14px]"
              name="password"
              value={newPassword}
              onChange={onChangeNewPassword}
              validations={[required, vpassword]}
            />
          </div>
          <div className='form-group w-full'>
            <label htmlFor='confirmPassword' className='text-[12px] font-RNSSanzMedium pb-1 text-[#8A9499]'>Confirm New Password</label>
            <Input
              type="password"
              className="form-control !w-full !bg-white text-[14px]"
              name="confirmPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              validations={[required, vpasswordConfirm]}
            />
          </div>
        </div>
        <div className='form-group w-full'>
          <button className='!w-[150px] !h-[40px] font-WorkSans !text-[14px]'>
            {isLoading ?
              <CgSpinner className={`text-white text-[30px] animate-spin`} /> :
              <span>Save changes</span>
            }
          </button>
        </div>
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
    </div>
  )
}

export default ProfilePassword;