import React from "react";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";

const LinkRow = ({ title, url, handleEdit, handleRemove }) => {
  return (
    <div className="w-full flex flex-row justify-between items-center -md:items-start border rounded-lg px-8 py-4"
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
      }}
    >
      <div className="grid grid-cols-2 -md:grid-cols-1 gap-4 flex-1">
        <p className="flex flex-col justify-start items-start space-y-2 -md:space-y-0">
          <span className="font-bold text-[14px]">Title: </span>
          <span className="text-[16px]">{title}</span>
        </p>
        <p className="flex flex-col justify-start items-start space-y-2 -md:space-y-0">
          <span className="font-bold text-[14px]">URL: </span>
          <a className="text-[16px] text-blue-600 underline" href={url} target="_blank">{url}</a>
        </p>
      </div>
      <div className="flex flex-row justify-end items-center space-x-4">
        <BsFillPencilFill
          className="text-[#10405A] cursor-pointer"
          onClick={handleEdit}
        />
        <BsFillTrash3Fill
          className="text-red-600 cursor-pointer"
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default LinkRow;
