import React, { useEffect, useState } from "react";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";

const VideoCard = ({ url, handleEdit, handleRemove }) => {
  const [youtubeId, setYoutubeId] = useState(null);
  useEffect(() => {
    if (url) {
      const videoId = url.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
      );
      if (videoId != null) {
        setYoutubeId(videoId[1]);
      } else {
        setYoutubeId(null);
      }
    }
  }, [url]);
  return (
    <div className="relative w-full rounded-lg">
      {youtubeId && (
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}`}
          allowFullScreen
          title="video"
          width={'100%'}
          height={'auto'}
          className="rounded-lg border"
        />
      )}
      <div className="absolute right-2 bottom-2 flex flex-row justify-center items-center space-x-4 p-2 rounded-full bg-white border-[#10405A] border">
        <BsFillTrash3Fill
          className="text-red-600 cursor-pointer"
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default VideoCard;
