import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MdOutlineClose } from "react-icons/md";
import { inviteAdmin } from "../../../actions/profile";
import { useDispatch } from "react-redux";

const InviteAdminModal = ({ closeUploadModal, profile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <div className="fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal font-WorkSans">
      <div className="relative mx-auto rounded-lg bg-white -md:w-[96%] md:w-[500px] my-[50px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-WorkSans">Invite user</div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={closeUploadModal}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center p-8">
          <p className="text-[14px] text-center">
            You are adding a user for{" "}
            <span className="font-bold text-[16px]">{`${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}</span>{" "}
            new memorial page
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (profile?.id) {
                setIsLoading(true);
                values.profileId = profile.id;
                console.log("values: ", values);

                dispatch(inviteAdmin(values))
                  .then(() => {
                    setIsLoading(false);
                    closeUploadModal();
                  })
                  .catch(() => {
                    setIsLoading(false);
                    closeUploadModal();
                  });
              }
            }}
          >
            {() => (
              <Form className="flex flex-col justify-start itesm-center space-y-6 w-[100%] pt-4">
                <div>
                  <label
                    htmlFor="email"
                    className="text-gray-600 text-sm block mb-1"
                  >
                    Email:
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="w-full border rounded px-4 py-3 text-[14px] focus:outline-none"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 !text-[12px]"
                  />
                </div>
                <div className="w-full flex flex-row justify-center items-center space-x-2 my-4">
                  <button
                    type="submit"
                    className={`px-7 py-2 font-RNSSanzBold text-[15px] text-white bg-[#10405A] border-[#10405A] rounded-[10px] border cursor-pointer`}
                    disabled={isLoading}
                  >
                    Send Invitation
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default InviteAdminModal;
