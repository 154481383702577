import React from "react";
import moment from "moment";
import BlankAvatarSVG from "../../../../assets/project/detail/blank.svg";
import { BsEyeFill, BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const ProfileCard = ({ profile, onView, onEdit, onDelete }) => {
  if (!profile) {
    return null;
  }
  const avatar = profile?.avatar
    ? `${BACKEND_URL}/uploads/profiles/avatar/${profile.avatar}`
    : BlankAvatarSVG;
  return (
    <div
      className="relative w-full rounded-lg flex flex-col justify-start items-center py-8 px-4 space-y-1 text-center"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      <div
        className="absolute top-4 right-4 p-2 rounded-lg bg-[#10405A] flex flex-row justify-center items-center cursor-pointer"
        onClick={onDelete}
      >
        <BsFillTrash3Fill className="text-white" />
      </div>
      <img
        src={avatar}
        className="rounded-full w-[200px] h-[200px] object-cover"
      />
      <p className="text-[22px] -sm:text-[18px] font-[600] font-WorkSans pt-2">
        {`${profile.firstName} ${profile.middleName} ${profile.lastName}`}
      </p>
      <p className="text-[13px]">Relationship: {profile.relation}</p>
      <p className="text-[14px] text-[#6c757d]">
        Created in {moment(profile.createdAt).format("MM/DD/YYYY")}
      </p>
      <p className="">{profile.status}</p>
      <div className="w-full grid grid-cols-2 gap-4 !mt-4">
        <div
          className="flex flex-row justify-center items-center space-x-4 bg-[#e9eaea] py-2 rounded-lg cursor-pointer"
          onClick={onView}
        >
          <BsEyeFill />
          <span className="text-[16px] -sm:text-[14px]">View</span>
        </div>
        <div
          className="flex flex-row justify-center items-center space-x-4 bg-[#10405A] py-2 rounded-lg text-white cursor-pointer"
          onClick={onEdit}
        >
          <BsFillPencilFill />
          <span className="text-[16px] -sm:text-[14px]">Edit</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
