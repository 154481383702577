import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { FaFacebook, FaTwitter, FaPinterest } from "react-icons/fa";

const ShareModal = ({ title, closeModal, shareUrl }) => {
  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-center justify-center z-20 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-[10px] bg-white my-[50px] -md:w-[96%] md:w-[400px]">
        <div className="w-full min-h-[60px] px-6 mt-4 pb-2 rounded-tl-[20px] rounded-tr-[20px] flex flex-row justify-between items-center">
          <span className="font-WorkSans text-[20px]">{title}</span>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={closeModal}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full px-6 pt-2 flex flex-col justify-center items-center pb-8">
          <div className="w-full flex flex-col justify-start items-start space-y-4">
            <p className="font-WorkSans text-[16px] text-center w-full">
              Share this link via
            </p>
            <div className="w-full flex flex-row justify-around items-center">
              <FaFacebook
                className="text-[50px] text-[#1877F2] cursor-pointer"
                onClick={() => {
                  window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    shareUrl
                  )}`;
                }}
              />
              <FaTwitter
                className="text-[50px] text-[#1DA1F2] cursor-pointer"
                onClick={() => {
                  window.location.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    shareUrl
                  )}`;
                }}
              />
              <FaPinterest
                className="text-[50px] text-red-500 cursor-pointer"
                onClick={() => {
                  window.location.href = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                    shareUrl
                  )}`;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
