import React from "react";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";

const ImageCard = ({ url, handleEdit, handleRemove }) => {
  return (
    <div className="relative w-full">
      <img src={url} className="w-full rounded-lg" />
      <div className="absolute right-4 top-4 flex flex-row justify-center items-center space-x-4 px-4 py-2 rounded-full bg-white border-[#10405A] border">
        <BsFillPencilFill
          className="text-[#10405A] cursor-pointer"
          onClick={handleEdit}
        />
        <BsFillTrash3Fill
          className="text-red-600 cursor-pointer"
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default ImageCard;
