import React, { useState, useMemo, useEffect } from "react";
import MainLayout from "../MainLayout";
import { FaPlus } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import ConfirmModal from "../global/ConfirmModal";
import ConfirmModalQR from "../global/ConfirmModalQR";
import { useDispatch, useSelector } from "react-redux";
import { createQR, disconnect, getQRCodes } from "../../actions/qr";
import DataTable from "react-data-table-component";
import SvgHeartQr from "./SvgHeartQr";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL_DEVELOPMENT;
const CustomLoader = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center space-y-8 mt-16">
      <CgSpinner className={`text-[#10405A] text-[70px] animate-spin`} />
      <span className="text-[16px] font-SenSans text-[#10405A]">
        Loading the data...
      </span>
    </div>
  );
};

const QR = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { qrs: result } = useSelector((state) => state.qr);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [selectedMedallion, setSelectedMedallion] = useState(null);

  const columns = useMemo(() => [
    {
      selector: (row, index) => index + 1 + (curPage - 1) * 10,
      width: "50px",
    },
    {
      name: "Medallion ID",
      columnName: "id",
      selector: (row) => row.id,
      sortable: true,
      minWidth: "300px",
    },
    {
      cell: (row) => (
        <SvgHeartQr url={`${BACKEND_URL}/uploads/qrcode/${row.id}.jpeg`} />
      ),
      width: "376px",
    },
    {
      name: "Medallion Status",
      cell: (row) => (
        <div className="text-[16px]">
          {row.profileId ? (
            <p className="px-4 py-2 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Connected
            </p>
          ) : (
            <p className="px-4 py-2 text-[#f06548] rounded-sm bg-[rgba(240,101,72,.1)] font-bold">
              New
            </p>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-end items-center space-x-2 text-[16px] cursor-pointer">
          {row.profileId && (
            <div
              className="flex flex-row justify-start items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px]"
              onClick={() => {
                setShowDisconnectModal(true);
                setSelectedMedallion(row);
              }}
            >
              <span>Disconnect</span>
            </div>
          )}
        </div>
      ),
      right: true,
    },
  ]);

  useEffect(() => {
    fetchQRcodes();
  }, [curPage]);

  const fetchQRcodes = () => {
    setIsLoading(true);
    dispatch(getQRCodes(10, curPage))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurPage(page);
  };

  return (
    <MainLayout>
      <div className="w-[100%] h-[100%] overflow-x-hidden font-WorkSans">
        <div className="w-full pt-[50px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 overflow-y-auto">
          <div className="w-full flex flex-row -lg:flex-col -lg:space-y-2 justify-between items-center mb-4">
            <span className="text-[24px] font-bold text-[#3D474D] mb-2 -lg:w-full">
              QR Codes Management
            </span>
            <div
              className="flex flex-row justify-center items-center space-x-2 text-white bg-[#10405A] px-4 py-2 rounded-xl cursor-pointer -sm:text-[13px] w-[200px]"
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              <FaPlus />
              <span>Create QR code</span>
            </div>
          </div>
          <div className="w-[100%] data-table rounded-lg my-8">
            <DataTable
              columns={columns}
              data={result.qrCodes}
              pagination
              paginationServer
              paginationTotalRows={result.count}
              progressPending={isLoading}
              progressComponent={<CustomLoader />}
              onChangePage={handlePageChange}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              noDataComponent={"There is no qr codes"}
            />
          </div>
        </div>
      </div>
      {showUploadModal && (
        <ConfirmModalQR
          title={"Create new QR code"}
          confirmTitle={"Create"}
          cancelTitle={"Cancel"}
          handleConfirm={(count) => {
            const payload = {
              count,
            };
            setIsLoading(true);
            dispatch(createQR(payload))
              .then(() => {
                setShowUploadModal(false);
                setIsLoading(false);
                dispatch(getQRCodes(20, curPage));
              })
              .catch(() => {
                setIsLoading(false);
              });
          }}
          handleCancel={() => {
            setShowUploadModal(false);
          }}
        />
      )}
      {showDisconnectModal && selectedMedallion && (
        <ConfirmModal
          title={"Disconnect medallion"}
          description={"Do you want to disconnect this medallion from profile?"}
          confirmTitle={"Disconnect"}
          cancelTitle={"Cancel"}
          handleCancel={() => {
            setShowDisconnectModal(false);
            setSelectedMedallion(null);
          }}
          handleConfirm={() => {
            const payload = {
              qrCodeId: selectedMedallion.id,
            };
            dispatch(disconnect(payload)).then(() => {
              setShowDisconnectModal(false);
              fetchQRcodes();
            });
          }}
        />
      )}
    </MainLayout>
  );
};

export default QR;
