import {
  PROFILE_CREATE_SUCCESS,
  PROFILE_CREATE_FAIL,
  PROFILE_GETALL_SUCCESS,
  PROFILE_GETALL_FAIL,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAIL,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_IMAGE_SUCCESS,
  PROFILE_IMAGE_FAIL,
  PROFILE_VIDEO_SUCCESS,
  PROFILE_VIDEO_FAIL,
  PROFILE_LINK_SUCCESS,
  PROFILE_LINK_FAIL,
  SHARE_PROFILE_GET_SUCCESS,
  SHARE_PROFILE_GET_FAIL,
} from "../actions/types";

const initialState = {
  profiles: [],
  profile: null,
  profileAdmins: [],
  profileImages: [],
  profileVideos: [],
  profileLinks: [],
  shareProfile: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_CREATE_SUCCESS:
      return {
        ...state,
      };
    case PROFILE_CREATE_FAIL:
      return {
        ...state,
      };
    case PROFILE_GET_SUCCESS:
      return {
        ...state,
        profile: payload.profile,
        profileAdmins: payload.profileAdmins,
      };
    case PROFILE_GET_FAIL:
      return {
        ...state,
        profile: {},
        profileAdmins: [],
      };
    case PROFILE_GETALL_SUCCESS:
      return {
        ...state,
        profiles: payload,
      };
    case PROFILE_GETALL_FAIL:
      return {
        ...state,
        profiles: [],
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case PROFILE_UPDATE_FAIL:
      return {
        ...state,
      };
    case PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        profileImages: payload,
      };
    case PROFILE_IMAGE_FAIL:
      return {
        ...state,
        profileImages: [],
      };
    case PROFILE_VIDEO_SUCCESS:
      return {
        ...state,
        profileVideos: payload,
      };
    case PROFILE_VIDEO_FAIL:
      return {
        ...state,
        profileVideos: [],
      };
    case PROFILE_LINK_SUCCESS:
      return {
        ...state,
        profileLinks: payload,
      };
    case PROFILE_LINK_FAIL:
      return {
        ...state,
        profileLinks: [],
      };
    case SHARE_PROFILE_GET_SUCCESS:
      return {
        ...state,
        shareProfile: payload.data,
      };
    case SHARE_PROFILE_GET_FAIL:
      return {
        ...state,
        shareProfile: null,
      };
    default:
      return state;
  }
}
