import React from 'react';
import { MdOutlineClose } from "react-icons/md";
import Avatar from 'react-avatar-edit';

const AvatarUploadModal = ({
  title,
  closeModal,
  handleUpload,
  setPreview,
  setFile
}) => {
  return (
    <div className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-center justify-center z-20 overflow-y-scroll overflow-x-hidden detail-modal`}>
      <div className="relative mx-auto rounded-[10px] bg-white my-[50px] -md:w-[96%] md:w-[400px]">
        <div className='w-full min-h-[60px] px-6 mt-4 pb-2 rounded-tl-[20px] rounded-tr-[20px] flex flex-row justify-between items-center'>
          <span className='font-WorkSans text-[20px]'>{title}</span>
          <div className='flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer'
            onClick={closeModal}
          >
            <MdOutlineClose className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className='w-full px-6 py-2 flex flex-col justify-center items-center'>
          <Avatar
            width={350}
            height={150}
            imageWidth={350}
            lineWidth={2}
            cropRadius={0}
            minCropRadius={0}
            exportAsSquare={true}
            onCrop={(prev) => setPreview(prev)}
            onClose={() => setPreview(null)}
            onFileLoad={(file) => {
              if (file) {
                setFile(file);
              }
            }}
            onBeforeFileLoad={(el) => {
              if (el.target.files[0].size > 10 * 1000000) { // Max 10MB
                console.log("file is so big size");
                el.target.value = "";
              }
            }}
            labelStyle={{
              marginTop: 0,
              cursor: 'pointer'
            }}
            label={'Choose a image'}
            borderStyle={{
              border: '1px dashed rgb(151, 151, 151)',
              borderRadius: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
          <div className='w-full flex flex-row justify-end items-center space-x-2 mt-7 mb-5'>
            <div className='px-7 py-1 font-WorkSans text-[15px] text-[#10405A] rounded-[5px] bg-[#eef0f2] border border-[#eef0f2] cursor-pointer'
              onClick={() => closeModal()}
            >
              Cancel
            </div>
            <div className='px-7 py-1 font-WorkSans text-[15px] text-white bg-[#10405A] rounded-[5px] border border-[#10405A] cursor-pointer'
              onClick={() => handleUpload()}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvatarUploadModal;